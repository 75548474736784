import { AppContext } from "../../library/context";
import { useContext, useEffect, useState } from "react";
import { cancelSubscription, getSubscription } from "../../api";
import { alertError, alertInfo } from "../../library/alert";
import Spinner from "../../components/Spinner";
import ErrorMessage from "../../components/ErrorMessage";

function SubscriptionSettingsForm() {
  const appData = useContext(AppContext);
  const [subscription, setSubscription] = useState<any>(null);

  useEffect(() => {
    async function loadData() {
      const response: any = await getSubscription(appData.cid as number);
      if (response && !response.error) {
        setSubscription(response);
      } else {
        setSubscription({ status: "inactive" });
      }
    }
    loadData();
  }, [appData.cid]);

  const onClickCancelSubscription = async () => {
    if (window.confirm(`Are you sure you want to cancel subscription?`)) {
      const response: any = await cancelSubscription({ cid: appData.cid });
      if (response && !response.error) {
        setSubscription(response);
        alertInfo(
          `We are sad to see you go. Your subscription will end on ${new Date(
            response.current_period_end * 1000
          ).toDateString()}`
        );
      } else {
        alertError(response.error);
      }
    }
  };

  const hasActiveSubscription = () => {
    return (
      subscription &&
      subscription.status === "active" &&
      !subscription.cancel_at_period_end
    );
  };

  const subscriptionEnded = () => {
    return subscription && subscription.status !== "active";
  };

  const subscriptionGoingToEnd = () => {
    return (
      subscription &&
      subscription.status === "active" &&
      subscription.cancel_at_period_end
    );
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mt-1">
                <label htmlFor="inputText" className="col-form-label">
                  <b>Subscription Details</b>
                </label>
                <div className="col-sm-12">
                  {!subscription && <Spinner />}
                  {hasActiveSubscription() && (
                    <div className="row">
                      <div className="col-auto">
                        <p className="m-0 p-0">
                          Next Payment:{" "}
                          {new Date(
                            subscription.current_period_end * 1000
                          ).toDateString()}
                        </p>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={onClickCancelSubscription}
                        >
                          Cancel Subscription
                        </button>
                      </div>
                    </div>
                  )}
                  {subscription && subscriptionGoingToEnd() && (
                    <p className="text-secondary">
                      Your subscription ends on{" "}
                      {new Date(
                        subscription.current_period_end * 1000
                      ).toDateString()}
                      .
                    </p>
                  )}
                  {subscription && subscriptionEnded() && (
                    <ErrorMessage message="You do not have an active subscription!" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionSettingsForm;
