import { Link } from "react-router-dom";
import { FAQS } from "./data";

function FAQ() {
  const content = (
    <>
      <div className="pagetitle">
        <h1>Frequently Asked Questions</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Pages</li>
            <li className="breadcrumb-item active">
              Frequently Asked Questions
            </li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="accordion accordion-flush" id="faq-group-1">
                  {FAQS.map((faq, index) => (
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          data-bs-target={`#faqs-${index}`}
                          type="button"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`faqs-${index}`}
                        className="accordion-collapse collapse"
                        data-bs-parent={`#faqs-${index}`}
                      >
                        <div className="accordion-body">
                          {faq.answer}
                          <br />
                          Read the{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://site.dejavuly.com/documentation"
                          >
                            Documentation
                          </a>{" "}
                          here.
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  return content;
}

export default FAQ;
