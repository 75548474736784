import Spinner from "../Spinner";

type StatsCardProps = {
  loading: boolean;
  value: string | null;
  label: string;
  className?: string;
  icon?: string;
};

export const StatsCard = ({
  loading,
  value,
  label,
  icon,
  className,
}: StatsCardProps) => {
  return (
    <div className={`stats-card card info-card ${className}`}>
      <div className="card-body">
        <h5 className="card-title">
          {label}{" "}
          <span>
            <i
              title={"Total count in selected date range."}
              className="bi bi-info-circle-fill ms-1"
            ></i>
          </span>
        </h5>

        <div className="d-flex align-items-center">
          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
            <i className={icon}></i>
          </div>
          <div className="ps-3">
            <h6>{loading ? <Spinner /> : value}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
