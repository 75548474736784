import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Header from "./components/Header";
import SideBar from "./components/Sidebar";
import Footer from "./components/Footer";
import SequencesCreate from "./pages/Sequences/Create";
import SequencesList from "./pages/Sequences/List";
import SelectorsDefine from "./pages/Selectors/Define";
import SelectorsList from "./pages/Selectors/List";
import ContentsCreate from "./pages/Contents/Create";
import ContentsList from "./pages/Contents/List";
import WatchersCreate from "./pages/Watchers/Create";
import WatchersList from "./pages/Watchers/List";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Faq from "./pages/Faq";
import Support from "./pages/Support";
import { AppContext } from "./library/context";
import { useCallback, useEffect, useState } from "react";
import { verifyLogin } from "./api";
import Spinner from "./components/Spinner";
import { alertInfo } from "./library/alert";
import UnauthHeader from "./components/UnauthHeader";
import PageNotFound from "./components/PageNotFound";
import ResetPassword from "./pages/ResetPassword";
import SetNewPassword from "./pages/SetNewPassword";
import Stats from "./pages/Stats";
import Embed from "./pages/Embed";
import Settings from "./pages/Settings";
import { User } from "./library/types";

function WithHeaderAndSidebar() {
  const navigate = useNavigate();
  const [unAuthRoute, setUnAuthRoute] = useState(false);
  const [cid, setCid] = useState<number | null>(null);
  const [api_key, setApi_key] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setCid(null);
    setUser(null);
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    async function loadData() {
      const UN_AUTH_ROUTES = ["/support"];
      const responseJson: any = await verifyLogin();
      if (responseJson && !responseJson.error) {
        setCid(responseJson.id);
        setApi_key(responseJson.api_key);
        setUser(responseJson);
      } else if (!UN_AUTH_ROUTES.includes(window.location.pathname)) {
        navigate("/login");
        alertInfo("Login to Continue!");
      } else {
        setUnAuthRoute(true);
      }
    }
    if (cid === null) {
      loadData();
    }
  }, [cid, navigate]);

  if (cid) {
    return (
      <AppContext.Provider
        value={{
          api_key,
          setApi_key,
          cid,
          setCid,
          user,
          setUser,
        }}
      >
        <Header user={user} logout={logout} />
        <SideBar />
        <main id="main" className="main">
          <Outlet />
        </main>
      </AppContext.Provider>
    );
  }
  if (unAuthRoute) {
    return (
      <>
        <UnauthHeader />
        <div className="container mt-5">
          <Outlet />
        </div>
      </>
    );
  }
  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-auto">
        <Spinner />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/sequence/create" element={<SequencesCreate />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/sequence/list" element={<SequencesList />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/content/create" element={<ContentsCreate />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/content/list" element={<ContentsList />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/selectors/list" element={<SelectorsList />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/selectors/define" element={<SelectorsDefine />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/watchers/create" element={<WatchersCreate />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/watchers/list" element={<WatchersList />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/setnewpassword" element={<SetNewPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<Landing />} />

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/faq" element={<Faq />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/support" element={<Support />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/embed" element={<Embed />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/settings" element={<Settings />} />
        </Route>

        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/stats" element={<Stats />} />
        </Route>

        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
