import { StatsCard } from "../../components/StatsCard";
import { CountStat } from "../../library/types";

type CardsProp = {
  visitors: CountStat;
  uniqueVisitors: CountStat;
  watchersViewed: CountStat;
  watchersClicked: CountStat;
  contentViewed: CountStat;
  contentClicked: CountStat;
  orchestrations: CountStat;
};

function Cards({
  visitors,
  uniqueVisitors,
  watchersViewed,
  watchersClicked,
  contentViewed,
  contentClicked,
  orchestrations,
}: CardsProp) {
  return (
    <>
      <div className="row mt-5 ms-4 me-4">
        <div className="col-auto">
          <StatsCard
            label="Visitors"
            loading={visitors.loading}
            value={(visitors.value || 0).toLocaleString()}
            icon="bi bi-people"
            className="visitor-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Unique Visitors"
            loading={uniqueVisitors.loading}
            value={(uniqueVisitors.value || 0).toLocaleString()}
            icon="bi bi-person-check"
            className="visitor-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Watchers Viewed"
            loading={watchersViewed.loading}
            value={(watchersViewed.value || 0).toLocaleString()}
            icon="bi bi-eye-fill"
            className="watcher-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Watchers Clicked"
            loading={watchersClicked.loading}
            value={(watchersClicked.value || 0).toLocaleString()}
            icon="bi bi-eye-fill"
            className="watcher-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Content Viewed"
            loading={contentViewed.loading}
            value={(contentViewed.value || 0).toLocaleString()}
            icon="bi bi-card-heading"
            className="content-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Content Clicked"
            loading={contentClicked.loading}
            value={(contentClicked.value || 0).toLocaleString()}
            icon="bi bi-card-heading"
            className="content-card"
          />
        </div>
        <div className="col-auto">
          <StatsCard
            label="Orchestrations"
            loading={orchestrations.loading}
            value={(orchestrations.value || 0).toLocaleString()}
            icon="bi bi-cloud-arrow-up"
            className="visitor-card"
          />
        </div>
      </div>
    </>
  );
}

export default Cards;
