import { Link } from "react-router-dom";
import PersonalSettingsForm from "./PersonalSettingsForm";
import AppSettingsForm from "./AppSettingsForm";
import SubscriptionSettingsForm from "./SubscriptionSettingsForm";

function Settings() {
  return (
    <>
      <div className="pagetitle">
        <h1>Settings</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Settings</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-bod p-3">
                <SubscriptionSettingsForm />
                <AppSettingsForm />
                <PersonalSettingsForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Settings;
