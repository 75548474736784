import { AppContext } from "../../library/context";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { getAppSettings, updateAppSettings } from "../../api";
import { alertSuccess } from "../../library/alert";
import Spinner from "../../components/Spinner";
import { AppSettings as AppSettingsType } from "../../library/types";
import ErrorMessage from "../../components/ErrorMessage";

function AppSettingsForm() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [appSettings, setAppSettings] = useState<AppSettingsType>({
    domain: "",
    reset_visitors: 365,
  });

  useEffect(() => {
    async function loadSettings() {
      setLoading(true);
      const response: any = await getAppSettings();
      if (response && !response.error) {
        setAppSettings(response);
      }
      setLoading(false);
    }
    loadSettings();
  }, [appData.cid]);

  const validationSchema = Yup.object({
    domain: Yup.string()
      .url("Not a valid url. Should be https://example.com")
      .required("Domain is required.")
      .max(300, "Max 300 chars alowed in domain url."),
    reset_visitors: Yup.number().required("Please enter reset visitors days."),
  });

  const validate = async () => {
    try {
      await validationSchema.validate({ ...appSettings });
      return "";
    } catch (err: any) {
      return err.errors.join(", ");
    }
  };

  const onClickUpdateAppSettings = async (e: any) => {
    setError("");
    e.preventDefault();
    const error = await validate();
    if (error) {
      setError(error);
      return;
    }
    setSaving(true);
    const payload = { ...appSettings };
    const response: any = await updateAppSettings(payload);
    setSaving(false);
    if (response && !response.error) {
      alertSuccess("App settings updated");
    } else {
      setError(response.error);
    }
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mt-1">
                <label htmlFor="inputText" className="col-form-label">
                  <b>App Settings</b>
                </label>
              </div>
              <div className="row">
                {loading ? (
                  <div className="ml-2 mt-2">
                    <Spinner />
                  </div>
                ) : (
                  <form onSubmit={onClickUpdateAppSettings}>
                    <div className="row mb-3 mt-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Domain
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          id="domain"
                          placeholder="Enter Authorized Domain"
                          className="form-control"
                          value={appSettings.domain}
                          required
                          onChange={(e) =>
                            setAppSettings({
                              ...appSettings,
                              domain: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3 mt-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Reset Visitors Days
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="number"
                          id="reset_visitors"
                          className="form-control"
                          value={appSettings.reset_visitors}
                          required
                          onChange={(e) =>
                            setAppSettings({
                              ...appSettings,
                              reset_visitors: parseInt(e.target.value, 10),
                            })
                          }
                        />
                      </div>
                    </div>
                    <ErrorMessage message={error} />
                    <div className="row mb-3">
                      <div className="col-6 col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={saving}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppSettingsForm;
