import { TablePagination } from "react-pagination-table";

type PagedTableProps = {
  headers: string[];
  columns: string;
  data: any;
  title?: string;
  subTitle?: string;
  perPageItemCount?: number;
};

const DEFAULT_COUNT_PER_PAGE = 10;
export const PagedTable = ({
  headers,
  data,
  columns,
  title = "",
  subTitle = "",
  perPageItemCount,
}: PagedTableProps) => {
  if (data.length > 0) {
    return (
      <div className="table-responsive-sm">
        <TablePagination
          title={title}
          subTitle={subTitle}
          headers={headers}
          data={data}
          columns={columns}
          perPageItemCount={
            perPageItemCount ? perPageItemCount : DEFAULT_COUNT_PER_PAGE
          }
          totalCount={data.length}
          className="table table-bordered mt-4"
          paginationClassName="pagination"
        />
      </div>
    );
  } else {
    return null;
  }
};
