import { Link } from "react-router-dom";

function Header({ user, logout }: { user: any; logout: () => void }) {
  const onClickToggleSidebar = () => {
    document.querySelector("body")?.classList.toggle("toggle-sidebar");
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/dashboard" className="logo d-flex align-items-center">
          <img
            src="/assets/img/dejavuly-logo-transparent.png"
            alt="DejaVuly Dashboard"
            style={{ maxHeight: 50 }}
          />
        </Link>
        <i
          className="bi bi-list toggle-sidebar-btn"
          onClick={onClickToggleSidebar}
        ></i>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              {user?.first_name && user?.last_name && (
                <img
                  src={`https://ui-avatars.com/api/?name=${user?.first_name}${user?.last_name}`}
                  alt="Profile"
                  className="rounded-circle"
                />
              )}
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {user?.first_name}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li>
                <Link
                  to="/"
                  className="dropdown-item d-flex align-items-center"
                  onClick={logout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
