import { useRef } from "react";
import {
  getFormattedDateForInput,
  getRelativeDate,
} from "../../library/utility";
import Spinner from "../Spinner";

type DateRangeProps = {
  onClickSubmit: (from?: string, to?: string) => void;
  setFromDate: (str: string) => void;
  setToDate: (str: string) => void;
  fromDate: string;
  toDate: string;
  disabled: boolean;
};

function DateRange({
  disabled,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  onClickSubmit,
}: DateRangeProps) {
  const fromDateRef = useRef<any>(null);
  const handleFromDateClick = () => {
    fromDateRef.current?.showPicker();
  };

  const toDateRef = useRef<any>(null);
  const handleToDateClick = () => {
    toDateRef.current?.showPicker();
  };

  const onClickFilter = (e: any) => {
    const filter = e.target.id;
    if (filter) {
      const to = getFormattedDateForInput(getRelativeDate(0).toISOString());
      let from = getFormattedDateForInput(getRelativeDate(0).toISOString());

      switch (filter) {
        case "todayFilter":
          from = getFormattedDateForInput(getRelativeDate(-1).toISOString());
          break;
        case "weekFilter":
          from = getFormattedDateForInput(getRelativeDate(-6).toISOString());
          break;
        case "monthFilter":
          from = getFormattedDateForInput(getRelativeDate(-29).toISOString());
          break;
        default:
          return;
      }
      onClickSubmit(from, to);
      setToDate(to);
      setFromDate(from);
    }
  };

  return (
    <>
      <div className="row mt-4 ms-4 me-4">
        <div className="col-auto mt-2">
          <b className="primary-title">Select Date Range</b>
        </div>
      </div>
      <div className="row ms-4 me-4">
        <div className="col-auto mt-2">
          <label className="date-label">From:</label>
          <input
            ref={fromDateRef}
            className="datetime ms-2"
            type="datetime-local"
            id="fromTime"
            value={fromDate}
            max={toDate}
            onChange={(e) => setFromDate(e.target.value)}
            onClick={handleFromDateClick}
          />
        </div>
        <div className="col-auto mt-2">
          <label className="date-label">To:</label>
          <input
            ref={toDateRef}
            className="datetime ms-2"
            type="datetime-local"
            id="toTime"
            value={toDate}
            min={fromDate}
            onChange={(e) => setToDate(e.target.value)}
            onClick={handleToDateClick}
          />
        </div>
        <div className="col-auto my-auto">
          <div>
            <a
              className="icon"
              onClick={onClickFilter}
              data-bs-toggle="dropdown"
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-three-dots"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-arrow">
              <li className="dropdown-header text-start">
                <h6>Filter</h6>
              </li>
              <li>
                <span
                  id="todayFilter"
                  className="dropdown-item"
                  onClick={onClickFilter}
                  style={{ cursor: "pointer" }}
                >
                  Last 24 Hours
                </span>
              </li>
              <li>
                <span
                  id="weekFilter"
                  className="dropdown-item"
                  onClick={onClickFilter}
                  style={{ cursor: "pointer" }}
                >
                  Last 7 Days
                </span>
              </li>
              <li>
                <span
                  id="monthFilter"
                  className="dropdown-item"
                  onClick={onClickFilter}
                  style={{ cursor: "pointer" }}
                >
                  Last 30 Days
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-auto mt-2">
          <button
            className="btn btn-primary"
            onClick={() => onClickSubmit()}
            disabled={disabled}
          >
            Submit
          </button>
        </div>
        {disabled && <Spinner customClassName="mt-2 text-secondary" />}
      </div>
    </>
  );
}

export default DateRange;
