import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getContents,
  getSeqTypes,
  getSequenceById,
  getSequenceOrder,
  saveSequence,
  saveSequenceOrder,
  updateSequence,
} from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/Spinner";
import { alertSuccess } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Contents, SeqType, Sequences } from "../../../library/types";

type SelectedContent = {
  contentId: string;
  startDate?: string;
  endDate?: string;
  dayOfWeek?: string;
};

const DAY_OF_WEEK_OPTIONS: any = [
  { value: 1, label: "Sunday" },
  { value: 2, label: "Monday" },
  { value: 3, label: "Tuesday" },
  { value: 4, label: "Wednesday" },
  { value: 5, label: "Thursday" },
  { value: 6, label: "Friday" },
  { value: 7, label: "Saturday" },
];

function Create() {
  const navigate = useNavigate();
  const appData = useContext(AppContext);
  const [SEQUENCE_TYPE_CALENDAR, setCalenderType] = useState(-1);
  const [SEQUENCE_TYPE_DAY_OF_WEEK, setDayOfWeekType] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string>("");
  const [seqTypes, setSeqTypes] = useState<SeqType[]>([]);
  const [contents, setContents] = useState<Contents[]>([]);
  const [selectedContents, setSelectedContents] = useState<SelectedContent[]>(
    []
  );
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState<number | null>(null);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const id = searchParams.get("id");
      const promises = [getContents(appData.cid as number), getSeqTypes()];
      if (id) {
        promises.push(getSequenceById(appData.cid as number, parseInt(id)));
        promises.push(getSequenceOrder(appData.cid as number, parseInt(id)));
      }
      const data: any = await Promise.all(promises);
      if (data && !data[1].error) {
        setSeqTypes(data[1] as SeqType[]);
        for (let i = 0; i < data[1].length; i++) {
          if (data[1][i].name === "Calendar") {
            setCalenderType(data[1][i].id);
          }
          if (data[1][i].name === "Day of Week") {
            setDayOfWeekType(data[1][i].id);
          }
        }
      }
      if (data && !data[0].error) {
        setContents(data[0] as Contents[]);
      }

      if (id) {
        if (data && !data[2].error) {
          const sequence: Sequences = data[2];
          setEditId(parseInt(sequence.id, 10));
          setName(sequence.sequence_name);
          setDescription(sequence.description);
          setType(parseInt(sequence.seq_type, 10));
        }

        if (data && !data[3].error) {
          const updatedSelectedContents = [];
          for (let i = 0; i < data[3].length; i++) {
            const start_date = data[3][i].start_date;
            const end_date = data[3][i].end_date;
            updatedSelectedContents.push({
              contentId: data[3][i].item,
              dayOfWeek: data[3][i].day_of_week,
              startDate: start_date ? start_date.split("T")[0] : "",
              endDate: end_date ? end_date.split("T")[0] : "",
            });
          }
          setSelectedContents(updatedSelectedContents);
        }
      }
      setLoading(false);
    }
    loadData();
  }, [appData, searchParams]);

  const validateSelectedContents = (
    selectedContents: SelectedContent[]
  ): string => {
    const dayOfWeekSet = new Set();
    for (let i = 0; i < selectedContents.length; i++) {
      if (!selectedContents[i].contentId) {
        return "Select valid content item from dropdown. ";
      }

      if (type === SEQUENCE_TYPE_DAY_OF_WEEK) {
        if (selectedContents.length > 7) {
          return "For day of week sequence type, only 7 content items can be added.";
        }
        if (!selectedContents[i].dayOfWeek) {
          return "Day of week is mising";
        }
        if (
          dayOfWeekSet.has(
            parseInt(selectedContents[i].dayOfWeek as string, 10)
          )
        ) {
          return "Cannot use same day of week more than once.";
        }
        dayOfWeekSet.add(parseInt(selectedContents[i].dayOfWeek as string, 10));
      } else if (type === SEQUENCE_TYPE_CALENDAR) {
        if (!selectedContents[i].startDate) {
          return "Start date is mising";
        }
        if (!selectedContents[i].endDate) {
          return "End date is mising";
        }
        if (
          new Date(selectedContents[i].startDate as string) >
          new Date(selectedContents[i].endDate as string)
        ) {
          return "Start date cannot be after end date.";
        }
      }
    }
    return "";
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    let error = "";

    if (!name) {
      error += "Name is required. ";
    }
    if (!description) {
      error += "Description is required. ";
    }
    if (!type) {
      error += "Type is required. ";
    }

    if (selectedContents.length < 1) {
      error += "Select at least one content item. ";
    }

    error += validateSelectedContents(selectedContents);

    if (error) {
      setError(error);
      return;
    }
    setSaving(true);
    const payload = {
      name,
      description,
      seq_type: type,
      cid: appData.cid,
      id: editId,
    };

    const responseJson: any = editId
      ? await updateSequence(payload)
      : await saveSequence(payload);
    if (!responseJson.error) {
      const seqOrderspayload: any = [];
      selectedContents.forEach((selectedContent, index) => {
        seqOrderspayload.push({
          order: index + 1,
          item: parseInt(selectedContent.contentId, 10),
          start_date:
            type === SEQUENCE_TYPE_CALENDAR ? selectedContent.startDate : "",
          end_date:
            type === SEQUENCE_TYPE_CALENDAR ? selectedContent.endDate : "",
          day_of_week: selectedContent.dayOfWeek
            ? parseInt(selectedContent.dayOfWeek, 10)
            : "",
        });
      });
      const sequenceId = editId
        ? editId
        : parseInt(responseJson?.insertId as string, 10);
      const responseSequenceOrder: any = await saveSequenceOrder(
        appData.cid as number,
        sequenceId,
        seqOrderspayload
      );
      if (!responseSequenceOrder.error) {
        setError("");
        setDescription("");
        setName("");
        setType(0);
        setSelectedContents([]);
        alertSuccess("Saved");
        navigate("/sequence/list");
      }
    } else {
      setError(responseJson.error);
    }
    setSaving(false);
  };

  const onClickAdd = () => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents.push({
      contentId: "",
      startDate: "",
      endDate: "",
      dayOfWeek: "",
    });
    setSelectedContents(updatedSelectedContents);
  };

  const onChangeStartDate = (e: any, index: number) => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents[index].startDate = e.target.value;
    setSelectedContents(updatedSelectedContents);
  };

  const onChangeEndDate = (e: any, index: number) => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents[index].endDate = e.target.value;
    setSelectedContents(updatedSelectedContents);
  };

  const onChangeDayOfWeek = (e: any, index: number) => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents[index].dayOfWeek = e.target.value;
    setSelectedContents(updatedSelectedContents);
  };

  const onChangeSelectedContent = (e: any, index: number) => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents[index].contentId = e.target.value;
    setSelectedContents(updatedSelectedContents);
  };

  const onRemoveSelectedContent = (e: any, index: number) => {
    const updatedSelectedContents = [...selectedContents];
    updatedSelectedContents.splice(index, 1);
    setSelectedContents(updatedSelectedContents);
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Sequences</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Sequences</li>
            <li className="breadcrumb-item active">
              {editId ? "Update" : "Create"}
            </li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {!loading && (
                <div className="card-body pt-3">
                  <b className="primary-title">
                    {editId ? "Update" : "Create"} Sequence
                  </b>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <p>
                        <br />
                        Decide how to display your dynamic content.
                      </p>
                      <p>
                        We highly recommend you check out the{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://site.dejavuly.com/documentation"
                        >
                          Documentation
                        </a>
                        .{" "}
                      </p>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row mb-3 mt-3">
                      {" "}
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          id="sequence_name"
                          placeholder="Sequence Name"
                          className="form-control"
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-sm-5">
                        <textarea
                          id="sequence_description"
                          placeholder="Sequence Description"
                          className="form-control"
                          value={description}
                          required
                          onChange={(e) => setDescription(e.target.value)}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-2 col-form-label">Type</label>
                      <div className="col-sm-5">
                        <select
                          className="form-select"
                          id="sequence_type"
                          aria-label="Sequence Type"
                          value={type}
                          onChange={(e) =>
                            setType(parseInt(e.target.value, 10))
                          }
                          required
                        >
                          <option value="">Select Sequence Type</option>
                          {seqTypes.map((seqType, index) => (
                            <option key={seqType.id} value={seqType.id}>
                              {seqType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-2 col-form-label">
                        Content Sequence
                      </label>
                      <div className="col-sm-10">
                        {selectedContents.map((selectedContent, index) => (
                          <div key={index} className="row mb-3">
                            <div className="col-auto">
                              <label>
                                <small>Order</small>
                              </label>
                              <br />
                              <label className="col-form-label content-order">
                                {index + 1}
                              </label>
                            </div>

                            <div className="col-sm-4 col-md-4">
                              <label>
                                <small>Content Item</small>
                              </label>
                              <select
                                className="form-control mb-3 default-option"
                                value={selectedContent.contentId}
                                onChange={(e) =>
                                  onChangeSelectedContent(e, index)
                                }
                                title={`Select Content Item at Sequence ${
                                  index + 1
                                }`}
                                required
                              >
                                <option value="" disabled selected hidden>
                                  {`Select Content Item at Sequence ${
                                    index + 1
                                  }`}
                                </option>
                                {contents.map((content) => (
                                  <option
                                    title={content.description}
                                    key={content.id}
                                    value={content.id}
                                  >
                                    {content.item_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {type === SEQUENCE_TYPE_DAY_OF_WEEK && (
                              <div className="col-auto">
                                <label>
                                  <small>Day of Week</small>
                                </label>
                                <select
                                  className="form-control mb-3 default-option"
                                  value={selectedContent.dayOfWeek}
                                  onChange={(e) => onChangeDayOfWeek(e, index)}
                                  required
                                >
                                  <option value="" disabled selected hidden>
                                    Select day
                                  </option>
                                  {DAY_OF_WEEK_OPTIONS.map((dayOfWeek: any) => (
                                    <option
                                      key={dayOfWeek.value}
                                      value={dayOfWeek.value}
                                    >
                                      {dayOfWeek.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            {type === SEQUENCE_TYPE_CALENDAR && (
                              <div className="col-auto">
                                <label>
                                  <small>Start Date</small>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={selectedContent.startDate}
                                  min={`${new Date().getFullYear()}-${
                                    new Date().getMonth() < 9 ? "0" : ""
                                  }${
                                    new Date().getMonth() + 1
                                  }-${new Date().getDate()}`}
                                  placeholder="Start"
                                  onChange={(e) => onChangeStartDate(e, index)}
                                  required
                                />
                              </div>
                            )}
                            {type === SEQUENCE_TYPE_CALENDAR && (
                              <div className="col-auto">
                                <label>
                                  <small>End Date</small>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={selectedContent.endDate}
                                  min={selectedContent.startDate}
                                  onChange={(e) => onChangeEndDate(e, index)}
                                  required
                                />
                              </div>
                            )}

                            <div className="col-auto">
                              <label>
                                <small>Remove</small>
                              </label>
                              <br />
                              <button
                                type="button"
                                onClick={(e) =>
                                  onRemoveSelectedContent(e, index)
                                }
                                className="btn btn-danger hover-pointer"
                              >
                                <i className="bi bi-dash-circle-fill" />
                              </button>
                            </div>
                          </div>
                        ))}
                        {selectedContents.length <= 30 && (
                          <div className="row mb-3">
                            <div className="col-auto ">
                              <button
                                type="button"
                                className="btn btn-outline-secondary hover-pointer"
                                onClick={onClickAdd}
                                disabled={
                                  type === SEQUENCE_TYPE_DAY_OF_WEEK &&
                                  selectedContents.length >= 7
                                }
                              >
                                <i className="bi bi-plus-circle-fill" />
                                &nbsp;&nbsp;Add Content
                              </button>
                            </div>
                          </div>
                        )}
                        {contents.length > 0 &&
                          selectedContents.length >= 30 && (
                            <p className="text-secondary">
                              Note: Max 30 content items can be added.
                            </p>
                          )}
                      </div>
                    </div>
                    <ErrorMessage message={error} />
                    <div className="row mb-3">
                      <div className="col-sm-1">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={saving}
                        >
                          {editId ? "Update" : "Save"}
                        </button>
                      </div>
                      <div className="col-sm-1">
                        {editId && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              navigate("/sequence/list");
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {loading && (
                <div className="card-body pt-3">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Create;
