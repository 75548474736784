import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../../../library/context";
import { Contents, Selectors } from "../../../library/types";
import {
  getContentById,
  getSelectors,
  saveContent,
  updateContent,
} from "../../../api";
import { alertSuccess } from "../../../library/alert";
import Spinner from "../../../components/Spinner";
import ContentEditor from "../../../components/ContentEditor";
import ErrorMessage from "../../../components/ErrorMessage";

function Create() {
  const navigate = useNavigate();
  const appData = useContext(AppContext);
  const [initialContent, setInitialContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selector, setSelector] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [shouldTrackClicks, setShouldTrackClicks] = useState<boolean>(false);
  const [shouldTrackViews, setShouldTrackViews] = useState<boolean>(false);
  const [selectorOptions, setSelectorOptions] = useState<Selectors[]>([]);
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState<number | null>(null);

  useEffect(() => {
    async function loadData() {
      const response: any = await getSelectors(appData.cid as number);
      if (response && !response.error) {
        setSelectorOptions(response as Selectors[]);
      }
    }
    loadData();
  }, [appData]);

  useEffect(() => {
    async function loadEditData() {
      const id = searchParams.get("id");
      if (id) {
        setLoading(true);
        const response: any = await getContentById(
          appData.cid as number,
          parseInt(id)
        );
        if (response && !response.error) {
          const content: Contents = response;
          setContent(content.content);
          setInitialContent(content.content);
          setName(content.item_name);
          setDescription(content.description);
          setSelector(`${content.selector_id}`);
          setShouldTrackClicks(content.track_click === 1);
          setShouldTrackViews(content.track_view === 1);
          setEditId(parseInt(content.id, 10));
        }
        setLoading(false);
      }
    }
    loadEditData();
  }, [searchParams, appData]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    let error = "";
    if (!name) {
      error += "Name is required. ";
    }
    if (!description) {
      error += "Description is required. ";
    }
    if (!selector) {
      error += "Selector is required. ";
    }
    if (!content) {
      error += "Content is required. ";
    }
    if (error) {
      setError(error);
      return;
    }

    const payload = {
      item_name: name,
      description,
      selector_id: selector,
      content,
      cid: appData.cid,
      track_click: shouldTrackClicks ? 1 : 0,
      track_view: shouldTrackViews ? 1 : 0,
      id: editId,
    };

    const responseJson: any = editId
      ? await updateContent(payload)
      : await saveContent(payload);

    if (!responseJson.error) {
      alertSuccess("Saved");
      setError("");
      setDescription("");
      setName("");
      setContent("");
      setSelector("");
      navigate("/content/list");
    } else {
      setError(responseJson.error);
    }
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Content</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Content</li>
            <li className="breadcrumb-item active">
              {editId ? "Update" : "Create"}
            </li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {!loading && (
                <div className="card-body pt-3">
                  <b className="primary-title">
                    {editId ? "Update" : "Create"} Content
                  </b>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <p>
                        <br />
                        Use the online content creator below to create your
                        dynamic content. Feel free to upload images for future
                        use.
                        <br />
                        <br />
                        We highly recommend you check out the{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://site.dejavuly.com/documentation"
                        >
                          Documentation
                        </a>
                        .{" "}
                      </p>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row mb-3 mt-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          id="sequence_name"
                          placeholder="Content Name"
                          className="form-control"
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-sm-5">
                        <textarea
                          id="sequence_description"
                          placeholder="Content Description"
                          className="form-control"
                          value={description}
                          required
                          onChange={(e) => setDescription(e.target.value)}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Selector
                      </label>
                      <div className="col-sm-5">
                        <select
                          className="form-select"
                          id="selector"
                          placeholder="Choose Selector"
                          title="Choose Selector"
                          value={selector}
                          onChange={(e) => setSelector(e.target.value)}
                          required
                        >
                          <option value="">Choose Selector</option>
                          {selectorOptions.map((selectorOption) => (
                            <option
                              title={selectorOption.description}
                              key={selectorOption.id}
                              value={selectorOption.id}
                            >
                              {selectorOption.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Track Clicks
                      </label>
                      <div className="col-sm-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="shouldTrackClicks"
                          checked={shouldTrackClicks}
                          aria-label="Track Clicks"
                          onChange={(e) =>
                            setShouldTrackClicks(e.target.checked)
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Track Views
                      </label>
                      <div className="col-sm-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="shouldTrackViews"
                          checked={shouldTrackViews}
                          aria-label="Track Views"
                          onChange={(e) =>
                            setShouldTrackViews(e.target.checked)
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputText"
                        className="col-sm-2 col-form-label"
                      >
                        Content
                      </label>
                      <div className="col-sm-10">
                        {initialContent && (
                          <ContentEditor
                            onChange={setContent}
                            initialValue={initialContent}
                          />
                        )}
                        {!initialContent && (
                          <ContentEditor onChange={setContent} />
                        )}
                      </div>
                    </div>
                    <ErrorMessage message={error} />
                    <div className="row mb-3">
                      <div className="col-sm-1">
                        <button type="submit" className="btn btn-primary">
                          {editId ? "Update" : "Create"}
                        </button>
                      </div>
                      <div className="col-sm-1">
                        {editId && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              navigate("/content/list");
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {loading && (
                <div className="card-body pt-3">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Create;
