import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSequences, getWatchers } from "../../api";
import Spinner from "../../components/Spinner";
import { AppContext } from "../../library/context";
import { Sequences, Watchers } from "../../library/types";
import { alertSuccess } from "../../library/alert";
import ErrorMessage from "../../components/ErrorMessage";

function Embed() {
  const appData = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [sequences, setSequences] = useState<Sequences[]>([]);
  const [watchers, setWatchers] = useState<Watchers[]>([]);
  const [selectedWatchers, setSelectedWatchers] = useState<string[]>([]);
  const [selectedSequences, setSelectedSequences] = useState<string[]>([]);
  const [generatedCode, setGeneratedCode] = useState<string>("");

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const promises = [
        getSequences(appData.cid as number),
        getWatchers(appData.cid as number),
      ];
      const data: any = await Promise.all(promises);
      if (data && !data[0].error) {
        setSequences(data[0] as Sequences[]);
      }
      if (data && !data[1].error) {
        setWatchers(data[1] as Watchers[]);
      }
      setLoading(false);
    }
    loadData();
  }, [appData]);

  useEffect(() => {
    const generatedCodeToCopy = async () => {
      setGeneratedCode("");
      setError("");
      if (selectedSequences.length === 0 && selectedWatchers.length === 0) {
        return;
      }
      const config: any = {
        client: String(appData.cid),
        api_key: String(appData.api_key)
      };
      if (selectedSequences.length > 0) {
        config.sequences = selectedSequences;
      }
      if (selectedWatchers.length > 0) {
        config.watchers = selectedWatchers;
      }
      const code = `<script type="text/javascript">
let dejavuly_config = ${JSON.stringify(config, null, 4)}
</script>
<script src="https://cdn.dejavuly.com/dejavuly.js" type="text/javascript" defer></script>`;
      setGeneratedCode(code);
    };
    generatedCodeToCopy();
  }, [selectedSequences, selectedWatchers, appData.cid, appData.api_key]);

  const copy = () => {
    navigator.clipboard.writeText(generatedCode).then(
      function () {
        alertSuccess("Code copied to clipboard!");
      },
      function (err) {
        alertSuccess("Could not copy to clipboard!");
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const onChangeSequence = (e: any, name: string) => {
    const updated = [...selectedSequences];
    e.target.blur();
    if (e.target.checked) {
      updated.push(name);
    } else {
      const index = updated.indexOf(name);
      updated.splice(index, 1);
    }
    setSelectedSequences(updated);
  };

  const onChangeWatcher = (e: any, name: string) => {
    const updated = [...selectedWatchers];
    e.target.blur();
    if (e.target.checked) {
      updated.push(name);
    } else {
      const index = updated.indexOf(name);
      updated.splice(index, 1);
    }
    setSelectedWatchers(updated);
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Embed Code</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Embed</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {!loading && (
                <div className="card-body pt-3">
                  <b className="primary-title">Embed Code Generator</b>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <p className="m-0">
                        <br />
                        Select <b>at least one item</b> and paste the generated code
                        into the &lt;head&gt; tag of your web page.
                        <br />
                        <br />
                        Alternatively, paste the generated code just before the closing
                        &lt;/body&gt; tag.
                      </p>
                      <p className="m-0">
                        <br />
                        For best results select only the sequences, or watchers,
                        that are relevant to that web page. Choosing only the relevant
                        items also eliminates unnecessary orchestrations.
                      </p>
                    </div>
                  </div>
                  <form id="embedCodeForm">
                    <div className="row mt-5">
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 mt-3" style={{ maxHeight: 300, overflow: 'auto', marginTop: 0 }}>
                        <label className="col-form-label text-dark">
                          <b>Sequences (scrolls down)</b>
                        </label>
                        {sequences.map((sequence, index) => {
                          return (
                            <div className="form-check mt-2" key={index}>
                              <input
                                className="form-check-input bg"
                                type="checkbox"
                                name="selectedSequences"
                                onChange={(e) =>
                                  onChangeSequence(e, sequence.sequence_name)
                                }
                              />
                              <label className="form-check-label">
                                {sequence.sequence_name}
                              </label>
                            </div>
                          );
                        })}
                        {sequences.length === 0 ? (
                          <p className="text-muted">No sequences defined.</p>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6 mt-3" style={{ maxHeight: 300, overflow: 'auto', marginTop: 0 }}>
                        <label className="col-form-label text-dark">
                          <b>Watchers (scrolls down)</b>
                        </label>
                        {watchers.map((watcher, index) => {
                          return (
                            <div className="form-check mt-2" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="selectedWatchers"
                                onChange={(e) =>
                                  onChangeWatcher(e, watcher.name)
                                }
                              />
                              <label className="form-check-label">
                                {watcher.name}
                              </label>
                            </div>
                          );
                        })}
                        {watchers.length === 0 ? (
                          <p className="text-muted">No watchers defined.</p>
                        ) : null}
                      </div>
                    </div>
                    {generatedCode && (
                      <div className="row mb-3">
                        <label className="col-form-label text-dark">
                          <b>Embed Code</b>
                        </label>
                        <div className="col-12">
                          <pre>
                            <code>{generatedCode}</code>
                          </pre>
                        </div>
                      </div>
                    )}
                    <ErrorMessage message={error} />
                    <div className="row mb-3">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={copy}
                          disabled={!generatedCode}
                        >
                          Copy To Clipboard
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {loading && (
                <div className="card-body pt-3">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Embed;
