import InnerHTML from "dangerously-set-html-content";

function Footer() {
  const robotLogo = `<div class="text-center"><br><a href="https://site.dejavuly.com" target="_blank"><img src="/assets/img/dejavuly-logo-transparent.png" alt="DejaVuly Dynamic Content Orchestration and View/Click Tracking" style="max-width:10%;min-width:10%;"></a></div>`;
  return (
    <footer
      id="footer"
      className="footer"
      data-version={process.env.REACT_APP_VERSION}
    >
      <div className="copyright">
        &copy; Copyright{" "}
        <strong>
          <span>DejaVuly</span>
        </strong>
        . All Rights Reserved. USA.
      </div>
      <InnerHTML html={robotLogo} />
    </footer>
  );
}

export default Footer;
