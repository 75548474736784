import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteSelector, getSelectors } from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import { PagedTable } from "../../../components/PagedTable";
import Spinner from "../../../components/Spinner";
import { alertError, alertInfo } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Selectors } from "../../../library/types";

function List() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectors, setSelectors] = useState<Selectors[]>([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setError("");
      const response: any = await getSelectors(appData.cid as number);
      if (response && !response.error) {
        setSelectors(response as Selectors[]);
      }
      if (response && response.length === 0) {
        setError("No selectors available.");
      }
      setLoading(false);
    }
    loadData();
  }, [appData]);

  const onClickDelete = async (selector: Selectors) => {
    if (window.confirm(`Are you sure you want to delete ${selector.name}?`)) {
      const response: any = await deleteSelector(
        appData.cid as number,
        parseInt(selector.id, 10)
      );
      if (response && !response.error) {
        alertInfo(`${selector.name} deleted!`);
        const updatedSelectors = selectors.filter(function (obj) {
          return obj.id !== selector.id;
        });
        setSelectors(updatedSelectors);
      } else {
        alertError(response.error);
      }
    }
  };

  const getTableData = () => {
    const tableData: any = {};
    tableData.headers = ["Name", "Selector", "Edit", "Delete"];
    tableData.columns = "name.selector.edit.delete";
    tableData.data = [];
    selectors.forEach((selector) => {
      tableData.data.push({
        name: (
          <span>
            {selector.name}
            <br />
            <small className="text-sm hidden-sm">{selector.description}</small>
          </span>
        ),
        selector: selector.selector,
        edit: (
          <Link
            title="Edit"
            className="btn-info btn btn-sm btn-warning"
            to={`/selectors/define?id=${selector.id}`}
          >
            Edit
          </Link>
        ),
        delete: (
          <button
            title="Delete"
            onClick={() => onClickDelete(selector)}
            className="btn btn-sm btn-danger ms-3"
          >
            Delete
          </button>
        ),
      });
    });

    return tableData;
  };

  return (
    <>
      {" "}
      <div className="pagetitle">
        <h1>Selectors</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Selectors</li>
            <li className="breadcrumb-item active">Edit</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-auto">
                    <Link
                      to="/selectors/define"
                      className="btn btn-outline-primary"
                    >
                      Create New Selector
                    </Link>
                  </div>
                </div>
                <PagedTable {...getTableData()} />
                {loading && <Spinner customClassName="mt-5" />}
                <ErrorMessage message={error} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default List;
