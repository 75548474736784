import { Theme, toast, ToastPosition } from "react-toastify";

const CONFIG = {
  position: "bottom-center" as ToastPosition,
  autoClose: 10000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "light" as Theme,
};

export function alertSuccess(msg: string) {
  toast.dismiss();
  toast.success(msg, CONFIG);
}

export function alertInfo(msg: string) {
  toast.dismiss();
  toast.info(msg, CONFIG);
}

export function alertError(msg: string) {
  toast.dismiss();
  toast.error(msg, CONFIG);
}
