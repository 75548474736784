import { useEffect, useState, useContext } from "react";
import { Contents, Watchers } from "../../library/types";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getContentById, getStats, getWatcherById } from "../../api";
import { StatsCard } from "../../components/StatsCard";
import { AppContext } from "../../library/context";
import {
  getFormattedDateForInput,
  getRelativeDate,
  reduceCount,
} from "../../library/utility";
import DateRange from "../../components/DateRange";
import LineChart from "../../components/LineChart";

type CountStat = {
  loading: boolean;
  value: string | null;
};

const ICON_MAP: any = {
  watcher: "bi bi-eye-fill",
  content: "bi bi-card-list",
};

const CLASSNAME_MAP: any = {
  watcher: "watcher-card",
  content: "content-card",
};

function Stats() {
  const appData = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    getFormattedDateForInput(getRelativeDate(-6).toISOString())
  );
  const [toDate, setToDate] = useState(
    getFormattedDateForInput(getRelativeDate(0).toISOString())
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [about, setAbout] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [objectId, setObjectId] = useState("");
  const [object, setObject] = useState<any>(null);
  const [objectStats, setobjectStats] = useState<CountStat>({
    loading: true,
    value: "",
  });
  const [objectUniqueStats, setObjectUniqueStats] = useState<CountStat>({
    loading: true,
    value: "",
  });
  const [chartData, setChartData] = useState([]);
  const [chartUniqueData, setChartUniqueData] = useState([]);

  async function loadData(inputFrom?: string, inputTo?: string) {
    if (type && id) {
      const from = inputFrom ?? fromDate;
      const to = inputTo ?? toDate;
      setLoading(true);
      const response: any = await getStats(
        from,
        to,
        type as string,
        id as string
      );
      setLoading(false);
      if (response && !response.error) {
        setobjectStats({
          loading: false,
          value: reduceCount(response),
        });
        setChartData(response);
      } else {
        setobjectStats({
          loading: false,
          value: "N/A",
        });
      }

      const uniqueStateResponse: any = await getStats(
        from,
        to,
        `UNIQUE_${type}` as string,
        id as string
      );
      if (uniqueStateResponse && !uniqueStateResponse.error) {
        setObjectUniqueStats({
          loading: false,
          value: reduceCount(uniqueStateResponse),
        });
        setChartUniqueData(uniqueStateResponse);
      } else {
        setObjectUniqueStats({
          loading: false,
          value: "N/A",
        });
      }
    }
  }

  useEffect(() => {
    loadData();
  }, [id, type]);

  useEffect(() => {
    async function loadPage() {
      const urlId = searchParams.get("id");
      const urlType = searchParams.get("type");
      let urlAbout = "";
      if (!urlId || !urlType) {
        navigate("/dashboard");
      }
      setId(urlId as string);
      setType(urlType as string);
      setObjectId(urlId as string);
      if (urlType?.includes("CONTENT")) {
        urlAbout = "content";
      }
      if (urlType?.includes("WATCHER")) {
        urlAbout = "watcher";
      }
      setAbout(urlAbout);

      if (urlAbout === "content") {
        const contentResponse: any = await getContentById(
          appData.cid as number,
          parseInt(urlId as string)
        );
        if (contentResponse && !contentResponse.error) {
          const content: Contents = contentResponse;
          setObject(content);
          console.dir(content);
        } else {
          navigate("/dashboard");
        }
      } else if (urlAbout === "watcher") {
        const watcherResponse: any = await getWatcherById(
          appData.cid as number,
          parseInt(urlId as string)
        );
        if (watcherResponse && !watcherResponse.error) {
          const watcher: Watchers = watcherResponse;
          setObject(watcher);
        } else {
          navigate("/dashboard");
        }
      }
    }
    loadPage();
  }, [searchParams, appData, navigate]);

  return (
    <>
      <div className="pagetitle" id={`${type}_${objectId}`}>
        <h1>{about && about[0].toUpperCase() + about.slice(1)} Stats </h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Stats</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="card row">
          <div className="col-lg-12">
            <DateRange
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              onClickSubmit={loadData}
              disabled={loading}
            />
            <div className="row mt-4 ms-4 me-4">
              <div className="row justify-content-center">
                <b className="primary-title">
                  {object?.item_name || object?.name}
                </b>
                <p>{object?.description}</p>
              </div>
            </div>
            <div className="row mt-4 ms-4 me-4">
              {type.includes("VIEW") && (
                <div className="row justify-content-center">
                  <div className="col-xxl-4 col-md-6 my-auto">
                    <StatsCard
                      label="Views"
                      loading={objectStats.loading}
                      value={objectStats.value}
                      icon={ICON_MAP[about]}
                      className={`${CLASSNAME_MAP[about]}`}
                    />
                  </div>
                  <div className="col-xxl-4 col-md-6 my-auto">
                    <StatsCard
                      label="Unique Views"
                      loading={objectUniqueStats.loading}
                      value={objectUniqueStats.value}
                      icon={ICON_MAP[about]}
                      className={CLASSNAME_MAP[about]}
                    />
                  </div>
                </div>
              )}
              {type.includes("CLICK") && (
                <div className="row justify-content-center">
                  <div className="col-xxl-4 col-md-6 my-auto">
                    <StatsCard
                      label="Clicks"
                      loading={objectStats.loading}
                      value={objectStats.value}
                      icon={ICON_MAP[about]}
                      className={CLASSNAME_MAP[about]}
                    />
                  </div>
                  <div className="col-xxl-4 col-md-6 my-auto">
                    <StatsCard
                      label="Unique Clicks"
                      loading={objectUniqueStats.loading}
                      value={objectUniqueStats.value}
                      icon={ICON_MAP[about]}
                      className={CLASSNAME_MAP[about]}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  {parseInt(objectStats?.value || "") > 0 && (
                    <LineChart
                      categories={chartData.map((a: any) => a.day)}
                      series={[
                        {
                          name: `${object?.item_name || object?.name} ${
                            type.includes("VIEW") ? "Views" : "Clicks"
                          }`,
                          data: chartData.map((a: any) => a.count),
                        },
                        {
                          name: `${object?.item_name || object?.name} Unique ${
                            type.includes("VIEW") ? "Views" : "Clicks"
                          }`,
                          data: chartUniqueData.map((a: any) => a.count),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stats;
