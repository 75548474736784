import { PagedTable } from "../../components/PagedTable";

type TablesProp = {
  watchersClickedByIdChartData: any[];
  watchersViewedByIdChartData: any[];
  contentClickedByIdChartData: any[];
  contentViewedByIdChartData: any[];
  visitorsUrlChartData: any[];
};

function Tables({
  watchersClickedByIdChartData,
  watchersViewedByIdChartData,
  contentClickedByIdChartData,
  contentViewedByIdChartData,
  visitorsUrlChartData,
}: TablesProp) {
  const getTableData = (clicks: any[], views: any[]) => {
    const tableData: any = {};
    tableData.data = [];
    const mergedData: any = {};

    clicks.forEach((click) => {
      mergedData[click.name] = {
        clicks: click.count,
        views: 0,
        sname: click.sname,
      };
    });

    views.forEach((view) => {
      if (mergedData[view.name]) {
        mergedData[view.name] = {
          ...mergedData[view.name],
          views: view.count,
          sname: view.sname,
        };
      } else {
        mergedData[view.name] = {
          views: view.count,
          clicks: 0,
          sname: view.sname,
        };
      }
    });
    const names = Object.keys(mergedData);

    names.forEach((name) => {
      tableData.data.push({
        name: name,
        clicks: mergedData[name].clicks,
        views: mergedData[name].views,
        sname: mergedData[name].sname,
      });
    });

    return tableData;
  };

  const getEmptyDataTable = (headers: string[]) => {
    return (
      <PagedTable
        headers={headers}
        columns={"error"}
        data={[{ error: "No results." }]}
      />
    );
  };

  return (
    <>
      <div className="row ms-4 me-4 stat-table">
        <div className="col-12 col-sm-12">
          <b className="primary-title">Watchers Stats</b>
          <PagedTable
            headers={["Watcher Name", "Clicks", "Views"]}
            columns={"name.clicks.views"}
            {...getTableData(
              watchersClickedByIdChartData,
              watchersViewedByIdChartData
            )}
            perPageItemCount={10}
          />
          {watchersClickedByIdChartData.length === 0 &&
            watchersViewedByIdChartData.length === 0 &&
            getEmptyDataTable(["Watcher Name", "Clicks", "Views"])}
        </div>
      </div>
      <div className="row mt-5 ms-4 me-4 mb-5 stat-table">
        <div className="col-12 col-sm-12">
          <b className="primary-title">Content Stats</b>
          <PagedTable
            headers={["Content Name", "Clicks", "Views", "Sequence Name"]}
            columns={"name.clicks.views.sname"}
            {...getTableData(
              contentClickedByIdChartData,
              contentViewedByIdChartData
            )}
            perPageItemCount={10}
          />
          {contentClickedByIdChartData.length === 0 &&
            contentViewedByIdChartData.length === 0 &&
            getEmptyDataTable([
              "Content Name",
              "Clicks",
              "Views",
              "Sequence Name",
            ])}
        </div>
      </div>
      <div className="row mt-5 ms-4 me-4 mb-5 stat-table">
        <div className="col-12 col-sm-12">
          <b className="primary-title">Visitor Stats</b>
          <PagedTable
            headers={["Host and Page", "Visitors", "Unique Visitors"]}
            columns={"url.visitors.unique_visitors"}
            data={visitorsUrlChartData}
            perPageItemCount={10}
          />
          {visitorsUrlChartData.length === 0 &&
            visitorsUrlChartData.length === 0 &&
            getEmptyDataTable([
              "Host and Page",
              "Visitors",
              "Unique Visitors",
            ])}
        </div>
      </div>
    </>
  );
}

export default Tables;
