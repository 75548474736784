import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteWatcher, getWatchers } from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import { PagedTable } from "../../../components/PagedTable";
import Spinner from "../../../components/Spinner";
import { alertError, alertInfo } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Watchers } from "../../../library/types";

function List() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [watchers, setWatchers] = useState<Watchers[]>([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setError("");
      const response: any = await getWatchers(appData.cid as number);
      if (response && !response.error) {
        setWatchers(response as Watchers[]);
      }
      if (response && response.length === 0) {
        setError("No watchers available.");
      }
      setLoading(false);
    }
    loadData();
  }, [appData]);

  const onClickDelete = async (watcher: Watchers) => {
    if (window.confirm(`Are you sure you want to delete ${watcher.name}?`)) {
      const response: any = await deleteWatcher(
        appData.cid as number,
        parseInt(watcher.id, 10)
      );
      if (response && !response.error) {
        alertInfo(`${watcher.name} deleted!`);
        const updatedWatchers = watchers.filter(function (obj) {
          return obj.id !== watcher.id;
        });
        setWatchers(updatedWatchers);
      } else {
        alertError(response.error);
      }
    }
  };

  const getTableData = () => {
    const tableData: any = {};
    tableData.headers = [
      "Name",
      "Selector",
      "Track Views",
      "Track Clicks",
      "Edit",
      "Delete",
    ];
    tableData.columns = "name.selector.track_view.track_click.edit.delete";
    tableData.data = [];
    watchers.forEach((watcher) => {
      tableData.data.push({
        name: (
          <span>
            {watcher.name}
            <br />
            <small className="text-sm hidden-sm">{watcher.description}</small>
          </span>
        ),
        selector: (
          <span>
            {watcher.selector_name}
            <br />
            <small className="text-sm hidden-sm">
              {watcher.selector_description}
            </small>
          </span>
        ),
        track_view:
          watcher.track_view === 1 ? (
            <Link to={`/stats?type=WATCHER_VIEW_ID&id=${watcher.id}`}>Y</Link>
          ) : (
            "-"
          ),
        track_click:
          watcher.track_click === 1 ? (
            <Link to={`/stats?type=WATCHER_CLICK_ID&id=${watcher.id}`}>Y</Link>
          ) : (
            "-"
          ),
        edit: (
          <Link
            title="Edit"
            className="btn-info btn btn-sm btn-warning"
            to={`/watchers/create?id=${watcher.id}`}
          >
            Edit
          </Link>
        ),
        delete: (
          <button
            title="Delete"
            onClick={() => onClickDelete(watcher)}
            className="btn btn-sm btn-danger ms-3"
          >
            Delete
          </button>
        ),
      });
    });

    return tableData;
  };

  return (
    <>
      {" "}
      <div className="pagetitle">
        <h1>Watchers</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Watchers</li>
            <li className="breadcrumb-item active">Edit</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-auto">
                    <Link
                      to="/watchers/create"
                      className="btn btn-outline-primary"
                    >
                      Create New Watcher
                    </Link>
                  </div>
                </div>
                <PagedTable {...getTableData()} />
                {loading && <Spinner customClassName="mt-5" />}
                <ErrorMessage message={error} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default List;
