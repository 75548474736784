import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

function SideBar() {
  const [currentRoute, setCurrentRoute] = useState<string>("dashboard");
  const location = useLocation();

  useEffect(() => {
    const pathname = window.location.pathname.replace("/", "");
    if (pathname) {
      setCurrentRoute(pathname);
    } else {
      setCurrentRoute("dashboard");
    }
  }, [location]);

  const isActive = (route: string) => {
    if (currentRoute.toLowerCase().includes(route.toLowerCase()))
      return "active";
    return "";
  };

  const isCollapsed = (route: string) => {
    if (currentRoute.toLowerCase().includes(route.toLowerCase())) return "";
    return "collapsed";
  };

  const hideSidebarOnMobile = () => {
    if (isMobile) {
      document.querySelector("body")?.classList.toggle("toggle-sidebar");
    }
    return true;
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapsed("dashboard")}`}
            to="/dashboard"
            onClick={hideSidebarOnMobile}
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapsed("embed")}`}
            to="/embed"
            onClick={hideSidebarOnMobile}
          >
            <i className="bi bi-code-square"></i>
            <span>Embed Code</span>
          </Link>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed`}
            data-bs-target="#selectors"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-ui-checks-grid"></i>
            <span>Selectors</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="selectors"
            className="nav-content collapse "
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
                className={isActive("selectors/define")}
                to="/selectors/define"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>Define</span>
              </Link>
            </li>
            <li>
              <Link
                className={isActive("selectors/list")}
                to="/selectors/list"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed`}
            data-bs-target="#watchers"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-eye-fill"></i>
            <span>Watchers</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="watchers"
            className="nav-content collapse "
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
                className={isActive("watchers/create")}
                to="/watchers/create"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>Create</span>
              </Link>
            </li>
            <li>
              <Link
                className={isActive("watchers/list")}
                to="/watchers/list"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed`}
            data-bs-target="#sequence-items"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-card-list"></i>
            <span>Content</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="sequence-items"
            className="nav-content collapse "
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
                className={isActive("content/create")}
                to="/content/create"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>Create</span>
              </Link>
            </li>
            <li>
              <Link
                className={isActive("content/list")}
                to="/content/list"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed`}
            data-bs-target="#sequence"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-menu-button-wide"></i>
            <span>Sequences</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="sequence"
            className="nav-content collapse "
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
                className={isActive("sequence/create")}
                to="/sequence/create"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>Create</span>
              </Link>
            </li>
            <li>
              <Link
                className={isActive("sequence/list")}
                to="/sequence/list"
                onClick={hideSidebarOnMobile}
              >
                <i className="bi bi-circle"></i>
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapsed("support")}`}
            to="/support"
            onClick={hideSidebarOnMobile}
          >
            <i className="bi bi-envelope"></i>
            <span>Support</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapsed("settings")}`}
            to="/settings"
            onClick={hideSidebarOnMobile}
          >
            <i className="bi bi-gear"></i>
            <span>Settings</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
