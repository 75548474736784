import { useContext, useState } from "react";
import { contactUs } from "../../api";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Spinner";
import { alertSuccess } from "../../library/alert";
import { AppContext } from "../../library/context";

function Support() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    const name = e.target[0].value;
    const email = e.target[1].value;
    const subject = e.target[2].value;
    const message = e.target[3].value;
    if (!email || !name || !message || !subject) {
      setError("Missing required values");
    }
    setSubmitting(true);
    const responseJson: any = await contactUs({
      name,
      email,
      subject,
      message,
      cid: appData.cid,
    });
    if (responseJson && !responseJson.error) {
      alertSuccess(
        "Message sent successfully. We will respond back within 24-48 hours."
      );
      e.target.reset();
    } else {
      setError("Error submitting form. Please check your email!");
    }
    setSubmitting(false);
  };

  const content = (
    <>
      <div className="pagetitle">
        <h1>Support</h1>
        <nav></nav>
      </div>

      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-6">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  {" "}
                  <i className="bi bi-envelope"></i>
                  <h3>Email</h3>
                  <p>
                    <b>
                      Please use the form on this page when requesting technical
                      support.
                    </b>
                    <br />
                    <br />
                    Provide a link to your website or web page whenever possible.
                    Thank you.
                    <br />
                    <br />
                    Most questions can be answered by reviewing the video
                    tutorials and documentation. Read the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://site.dejavuly.com/documentation"
                    >
                      Documentation
                    </a>{" "}
                    here.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card p-4">
              <h3>
                <b>Technical Support</b>
              </h3>
              <form className="php-email-form mt-3" onSubmit={onSubmit}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    {" "}
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 ">
                    {" "}
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={6}
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <ErrorMessage message={error} />
                  <div className="col-md-12">
                    <button type="submit" disabled={submitting}>
                      {submitting ? <Spinner /> : "Send Message"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  return content;
}

export default Support;
