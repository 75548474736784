import { AppSettings } from "../library/types";
import { getErrorMessage } from "../library/utility";

async function getSequenceById(cid: number, id: number) {
  return fetchHelper(`sequences/${id}/?cid=${cid}`, "GET", null);
}

async function getContents(cid: number) {
  return fetchHelper(`contents?cid=${cid}`, "GET", null);
}

async function getSequences(cid: number) {
  return fetchHelper(`sequences?cid=${cid}`, "GET", null);
}

async function getSequenceOrder(cid: number, sequenceId: number) {
  return fetchHelper(
    `seq_orders/sequence/${sequenceId}?cid=${cid}`,
    "GET",
    null
  );
}

async function getSelectors(cid: number) {
  return fetchHelper(`selectors?cid=${cid}`, "GET", null);
}

async function getSelectorById(cid: number, id: number) {
  return fetchHelper(`selectors/${id}/?cid=${cid}`, "GET", null);
}

async function getWatcherById(cid: number, id: number) {
  return fetchHelper(`watchers/${id}/?cid=${cid}`, "GET", null);
}

async function getContentById(cid: number, id: number) {
  return fetchHelper(`contents/${id}/?cid=${cid}`, "GET", null);
}

async function getWatchers(cid: number) {
  return fetchHelper(`watchers?cid=${cid}`, "GET", null);
}

async function getSeqTypes() {
  return fetchHelper(`seq_types`, "GET", null);
}

async function saveSequenceOrder(
  cid: number,
  sequenceId: number,
  payload: any
) {
  return fetchHelper(
    `seq_orders/sequence/${sequenceId}?cid=${cid}`,
    "PUT",
    payload
  );
}

async function saveWatcher(payload: any) {
  return fetchHelper(`watchers`, "POST", payload);
}

async function saveContent(payload: any) {
  return fetchHelper(`contents`, "POST", payload);
}

async function saveSequence(payload: any) {
  return fetchHelper(`sequences`, "POST", payload);
}

async function saveSelector(payload: any) {
  return fetchHelper(`selectors`, "POST", payload);
}

async function updateSelector(payload: any) {
  return fetchHelper(`selectors`, "PUT", payload);
}

async function updateWatcher(payload: any) {
  return fetchHelper(`watchers`, "PUT", payload);
}

async function updateContent(payload: any) {
  return fetchHelper(`contents`, "PUT", payload);
}

async function updateSequence(payload: any) {
  return fetchHelper(`sequences`, "PUT", payload);
}

async function deleteSelector(cid: number, id: number) {
  return fetchHelper(`selectors/${id}?cid=${cid}`, "DELETE", null);
}

async function deleteContent(cid: number, id: number) {
  return fetchHelper(`contents/${id}?cid=${cid}`, "DELETE", null);
}

async function deleteSequence(cid: number, id: number) {
  return fetchHelper(`sequences/${id}?cid=${cid}`, "DELETE", null);
}

async function deleteWatcher(cid: number, id: number) {
  return fetchHelper(`watchers/${id}?cid=${cid}`, "DELETE", null);
}

async function getStats(
  fromDate: string,
  toDate: string,
  type: string,
  id: string = ""
) {
  return fetchHelper(
    `stats?type=${type}&fromDate=${convertDateToUTC(
      new Date(fromDate)
    )}&toDate=${convertDateToUTC(new Date(toDate))}&id=${id}`,
    "GET",
    null
  );
}

function convertDateToUTC(date: Date) {
  const isoDateStr = new Date(date.getTime()).toISOString();
  const arr = isoDateStr.split(":");
  arr.pop();
  const result = arr.join(".");
  return result.replace(".", ":");
}

async function registerNewClient(payload: any) {
  return fetchHelper(`clients/register`, "POST", payload);
}

async function clientLogin(payload: any) {
  return fetchHelper(`clients/login`, "POST", payload);
}

async function verifyLogin() {
  return fetchHelper(`clients/verify`, "POST", null);
}

async function createSubscription(payload: any) {
  return fetchHelper(`clients/createSubscription`, "POST", payload);
}

async function cancelSubscription(payload: any) {
  return fetchHelper(`cancelSubscription`, "POST", payload);
}

async function getSubscription(cid: number) {
  return fetchHelper(`getSubscription?cid=${cid}`, "GET", null);
}

async function getAppSettings() {
  return fetchHelper(`settings`, "GET", null);
}

async function updateAppSettings(payload: AppSettings) {
  return fetchHelper(`settings`, "PUT", payload);
}

async function contactUs(payload: {
  name: string;
  email: string;
  message: string;
  subject: string;
  cid: number | null;
}) {
  return fetchHelper(`contactUs`, "POST", payload);
}

async function resetPassword(payload: { email: string }) {
  return fetchHelper(`clients/resetPassword`, "POST", payload);
}

async function setNewPassword(payload: {
  password: string;
  confirmPassword: string;
  token: string;
}) {
  return fetchHelper(`clients/setNewPassword`, "POST", payload);
}

async function updateClient(payload: {
  first_name: string;
  last_name: string;
  email: string;
}) {
  return fetchHelper(`profile`, "PUT", payload);
}

async function ping() {
  return fetchHelper(`ping`, "GET", null);
}

async function getTinymiceToken() {
  return fetchHelper(`tinymicetoken`, "POST", null);
}

async function fetchHelper(url: string, method: string, payload: any | null) {
  return new Promise((resolve) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
      method,
      body: payload ? JSON.stringify(payload) : null,
      headers: {
        token: localStorage.getItem("token") as string,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(await getErrorMessage(response));
      })
      .then((responseJson) => {
        if (responseJson.status) {
          resolve(responseJson.data || responseJson);
        }
        resolve(responseJson);
      })
      .catch((error) => {
        resolve({ error: error.message });
      });
  });
}

export {
  getSequenceById,
  getContents,
  saveSequenceOrder,
  getSequenceOrder,
  getSelectors,
  getWatchers,
  saveWatcher,
  saveSelector,
  updateSelector,
  deleteSelector,
  getSequences,
  getSeqTypes,
  getSelectorById,
  getWatcherById,
  updateWatcher,
  deleteWatcher,
  getContentById,
  deleteContent,
  updateContent,
  saveContent,
  saveSequence,
  deleteSequence,
  updateSequence,
  registerNewClient,
  clientLogin,
  verifyLogin,
  contactUs,
  resetPassword,
  setNewPassword,
  getStats,
  createSubscription,
  cancelSubscription,
  getSubscription,
  updateClient,
  getAppSettings,
  updateAppSettings,
  ping,
  getTinymiceToken,
};
