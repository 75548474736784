import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { setNewPassword } from "../../api";
import { alertError, alertSuccess } from "../../library/alert";

function SetNewPassword() {
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function loadToken() {
      const param = searchParams.get("token");
      if (param) {
        setToken(param);
      }
    }
    loadToken();
  }, [searchParams]);

  const onClickLogin = async () => {
    if (!password) alertError("Enter password.");
    if (!confirmPassword) alertError("Enter confirm password.");
    if (confirmPassword !== password) alertError("Passwords do not match.");
    if (confirmPassword && password && confirmPassword === password) {
      setLoading(true);
      const responseJson: any = await setNewPassword({
        confirmPassword,
        password,
        token,
      });
      setLoading(false);
      if (responseJson && !responseJson.error) {
        alertSuccess("Password reset successfull.");
        navigate("/login");
      }
      if (responseJson && responseJson.error) {
        alertError(responseJson.error);
      }
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img
                      src="/assets/img/dejavuly-logo-transparent.png"
                      alt="DejaVuly Dashboard"
                      style={{ maxHeight: 50 }}
                    />
                  </Link>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Reset your password
                      </h5>
                    </div>

                    <form className="row g-3 needs-validation">
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="yourPassword"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="yourConfirmPassword"
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          type="button"
                          onClick={onClickLogin}
                          disabled={loading}
                        >
                          Save Password
                        </button>
                      </div>
                      <div className="col-12">
                        <p className="mb-0">
                          Remember Password? <Link to="/login">Log in</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default SetNewPassword;
