import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteSequence, getSequences } from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import { PagedTable } from "../../../components/PagedTable";
import Spinner from "../../../components/Spinner";
import { alertError, alertInfo } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Sequences } from "../../../library/types";

function List() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sequences, setSequences] = useState<Sequences[]>([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setError("");
      const response: any = await getSequences(appData.cid as number);
      if (response && !response.error) {
        setSequences(response as Sequences[]);
      }
      if (response && response.length === 0) {
        setError("No sequences available.");
      }
      setLoading(false);
    }
    loadData();
  }, [appData]);

  const onClickDelete = async (sequence: Sequences) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${sequence.sequence_name}?`
      )
    ) {
      const response: any = await deleteSequence(
        appData.cid as number,
        parseInt(sequence.id, 10)
      );
      if (response && !response.error) {
        alertInfo(`${sequence.sequence_name} deleted!`);
        const updatedSequences = sequences.filter(function (obj) {
          return obj.id !== sequence.id;
        });
        setSequences(updatedSequences);
      } else {
        alertError(response.error);
      }
    }
  };

  const getTableData = () => {
    const tableData: any = {};
    tableData.headers = ["Name", "Type", "Edit", "Delete"];
    tableData.columns = "name.type.edit.delete";
    tableData.data = [];
    sequences.forEach((sequence) => {
      tableData.data.push({
        name: (
          <span>
            {sequence.sequence_name}
            <br />
            <small className="text-sm hidden-sm">{sequence.description}</small>
          </span>
        ),
        type: sequence.sequence_type_name,
        edit: (
          <Link
            title="Edit"
            className="btn-info btn btn-sm btn-warning"
            to={`/sequence/create?id=${sequence.id}`}
          >
            Edit
          </Link>
        ),
        delete: (
          <button
            title="Delete"
            onClick={() => onClickDelete(sequence)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        ),
      });
    });

    return tableData;
  };

  return (
    <>
      {" "}
      <div className="pagetitle">
        <h1>Sequences</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Sequences</li>
            <li className="breadcrumb-item active">Edit</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-auto">
                    <Link
                      to="/sequence/create"
                      className="btn btn-outline-primary"
                    >
                      Create New Sequence
                    </Link>
                  </div>
                </div>
                <PagedTable {...getTableData()} />
                {loading && <Spinner customClassName="mt-5" />}
                <ErrorMessage message={error} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default List;
