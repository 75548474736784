import { createContext } from "react";
import { User } from "./types";

export const AppContext = createContext<{
  api_key: string | null;
  setApi_key: (api_key: string) => void;
  cid: number | null;
  setCid: (id: number) => void;
  user: User | null;
  setUser: (user: User) => void;
}>({
  api_key: null,
  setApi_key: (api_key: string) => {},
  cid: null,
  setCid: (id: number) => {},
  user: null,
  setUser: (id: User) => {},
});
