import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteContent, getContents } from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import { PagedTable } from "../../../components/PagedTable";
import Spinner from "../../../components/Spinner";
import { alertError, alertInfo } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Contents } from "../../../library/types";

function List() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState<Contents[]>([]);

  const onClickDelete = async (content: Contents) => {
    if (
      window.confirm(`Are you sure you want to delete ${content.item_name}?`)
    ) {
      const response: any = await deleteContent(
        appData.cid as number,
        parseInt(content.id, 10)
      );
      if (response && !response.error) {
        alertInfo(`${content.item_name} deleted!`);
        const updatedContents = contents.filter(function (obj) {
          return obj.id !== content.id;
        });
        setContents(updatedContents);
      } else {
        alertError(response.error);
      }
    }
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setError("");
      const response: any = await getContents(appData.cid as number);
      if (response && !response.error) {
        setContents(response as Contents[]);
      }
      if (response && response.length === 0) {
        setError("No contents available.");
      }
      setLoading(false);
    }
    loadData();
  }, [appData]);

  const getTableData = () => {
    const tableData: any = {};
    tableData.headers = [
      "Name",
      "Selector",
      "Track Views",
      "Track Clicks",
      "Edit",
      "Delete",
    ];
    tableData.columns = "name.selector.track_view.track_click.edit.delete";
    tableData.data = [];
    contents.forEach((content) => {
      tableData.data.push({
        name: (
          <span>
            {content.item_name}
            <br />
            <small className="text-sm hidden-sm">{content.description}</small>
          </span>
        ),
        selector: (
          <span>
            {content.selector_name}
            <br />
            <small className="text-sm hidden-sm">
              {content.selector_description}
            </small>
          </span>
        ),
        track_view:
          content.track_view === 1 ? (
            <Link to={`/stats?&type=CONTENT_VIEW_ID&id=${content.id}`}>Y</Link>
          ) : (
            "-"
          ),
        track_click:
          content.track_click === 1 ? (
            <Link to={`/stats?type=CONTENT_CLICK_ID&id=${content.id}`}>Y</Link>
          ) : (
            "-"
          ),
        edit: (
          <Link
            title="Edit"
            className="btn-info btn btn-sm btn-warning"
            to={`/content/create?id=${content.id}`}
          >
            Edit
          </Link>
        ),
        delete: (
          <button
            title="Delete"
            onClick={() => onClickDelete(content)}
            className="btn btn-sm btn-danger ms-3"
          >
            Delete
          </button>
        ),
      });
    });

    return tableData;
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Content</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Content</li>
            <li className="breadcrumb-item active">List</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-auto">
                    <Link
                      to="/content/create"
                      className="btn btn-outline-primary"
                    >
                      Create New Content
                    </Link>
                  </div>
                </div>{" "}
                <PagedTable {...getTableData()} />
                {loading && <Spinner customClassName="mt-5" />}
                <ErrorMessage message={error} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default List;
