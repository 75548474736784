function PageNotFound() {
  return (
    <div className="container">
      <section className="section error-404 min-vh-80 d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h2>The page you are looking for doesn't exist.</h2>{" "}
        <a className="btn" href="/">
          Back to home
        </a>{" "}
        <img
          src="/assets/img/not-found.svg"
          className="py-5"
          alt="Page Not Found"
          height="400px"
        />
      </section>
    </div>
  );
}

export default PageNotFound;
