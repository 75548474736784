import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { alertError, alertSuccess } from "../../library/alert";
import { createSubscription, registerNewClient } from "../../api";

const STRIPE_MONTHLY_PRICE_ID = process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID;
function SignUpForm() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [priceId, setPriceId] = useState("");

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required("Please enter first name")
      .max(100, "Max 100 chars alowed in first name."),
    lastName: Yup.string()
      .trim()
      .required("Please enter last name")
      .max(100, "Max 100 chars alowed in last name."),
    email: Yup.string()
      .trim()
      .email("Please enter valid email")
      .required()
      .max(500, "Max 500 chars alowed in email."),
    password: Yup.string()
      .trim()
      .required("Please enter password")
      .max(20, "Max 20 chars alowed in password.")
      .min(8, "Min 8 chars required in password."),
    priceId: Yup.string().trim().required("Please select billing plan."),
  });

  const validate = async () => {
    try {
      await validationSchema.validate({
        lastName,
        firstName,
        email,
        password,
        priceId,
      });
      const cardNumber: any = elements?.getElement(CardElement);
      const isInvalid = cardNumber?._invalid;
      const isEmpty = cardNumber?._empty;

      const isValid = !(isEmpty || isInvalid);
      if (!isValid) {
        return "Provide all valid card details.";
      }
      return "";
    } catch (err: any) {
      return err.errors.join(", ");
    }
  };

  const register = async (e: any) => {
    try {
      e.preventDefault();
      const error = await validate();
      if (error) {
        alertError(error);
        return;
      }
      setLoading(true);

      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement)!,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email,
        },
      });
      if (paymentMethod?.error) {
        console.error("paymentMethodError", paymentMethod.error.message);
        throw new Error(paymentMethod.error.message);
      }

      // create a subscription
      const createSubscriptionResponseJson: any = await createSubscription({
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: `${firstName} ${lastName}`,
        email,
        priceId,
      });

      if (createSubscriptionResponseJson.error) {
        console.error(
          "createSubscriptionResponseJsonError",
          createSubscriptionResponseJson.error
        );
        throw new Error(createSubscriptionResponseJson.error);
      }

      const confirmPayment = await stripe?.confirmCardPayment(
        createSubscriptionResponseJson.clientSecret
      );
      if (confirmPayment?.error) {
        console.error("confirmPaymentError", confirmPayment.error.message);
        throw new Error(confirmPayment.error.message);
      }

      const responseJson: any = await registerNewClient({
        firstName,
        lastName,
        email,
        password,
        stripeCust: createSubscriptionResponseJson.stripeCust,
        stripeSub: createSubscriptionResponseJson.stripeSub,
      });
      setLoading(false);
      if (responseJson && !responseJson.error) {
        navigate("/login");
        alertSuccess("Signup success. Login to continue.");
        return;
      }
      alertError(responseJson.error);
    } catch (exception: any) {
      setLoading(false);
      alertError(exception.message);
    }
  };

  return (
    <form className="row g-3 needs-validation" onSubmit={register}>
      <div className="col-sm-12 col-md-6">
        <label htmlFor="yourName" className="form-label">
          First Name
        </label>
        <input
          type="text"
          name="name"
          className="form-control"
          id="firstName"
          required
          onChange={(e) => setFirstName(e.target.value)}
          maxLength={100}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <label htmlFor="yourName" className="form-label">
          Last Name
        </label>
        <input
          type="text"
          name="name"
          className="form-control"
          id="lastName"
          required
          onChange={(e) => setLastName(e.target.value)}
          maxLength={100}
        />
      </div>

      <div className="col-12">
        <label htmlFor="yourEmail" className="form-label">
          Your Email
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          id="yourEmail"
          required
          onChange={(e) => setEmail(e.target.value)}
          maxLength={500}
        />
      </div>

      <div className="col-12">
        <label htmlFor="yourPassword" className="form-label">
          Password
        </label>
        <input
          type="password"
          name="password"
          className="form-control"
          id="yourPassword"
          required
          onChange={(e) => setPassword(e.target.value)}
          maxLength={20}
        />
      </div>

      <div className="col-12">
        <div className="row">
          <label htmlFor="yourEmail" className="form-label">
            Select Billing Plan
          </label>
        </div>
        <div className="form-check pl-2">
          <input
            className="form-check-input pl-2"
            type="radio"
            name="paymentMethodOne"
            id="paymentMethodOne"
            onChange={(e) => {
              if (e.target.checked) {
                setPriceId(STRIPE_MONTHLY_PRICE_ID as string);
              }
            }}
            checked={priceId === STRIPE_MONTHLY_PRICE_ID}
            required
          />
          <label className="form-check-label" htmlFor="paymentMethod">
            USD 12.00/month
          </label>
        </div>
      </div>
      <div className="col-12">
        <label htmlFor="cardDetails" className="form-label">
          Card Details
        </label>
        <CardElement className="form-control form-control-lg" />
      </div>
      <div className="col-12">
        <span id="createAcct"></span>
        <div className="form-check">
          <input
            className="form-check-input"
            name="terms"
            type="checkbox"
            value=""
            id="acceptTerms"
            required
          />
          <label className="form-check-label" htmlFor="acceptTerms">
            I agree to and accept the <a href="#tandc">Terms and Conditions</a>.
          </label>
        </div>
      </div>
      <div className="col-12">
        <button
          className="btn btn-primary w-100"
          type="submit"
          disabled={loading}
        >
          Start Subscription
        </button>
      </div>
      <div className="col-12">
        <p className="mb-0">
          Already have an account? <Link to="/login">Log in</Link>.
        </p>
        <p>&nbsp;</p>
        <h5 id="tandc">Terms and Conditions</h5>
        <ol>
          <li>
            If ever you decide to stop using DejaVuly, you will remove all
            DejaVuly code from your websites.
          </li>
          <li>
            You have a basic understanding of HTML and HTML/CSS selectors or are
            willing to learn.
          </li>
          <li>
            An orchestration is a unit of work and equates roughly to 1
            orchestration for every 1-2 page loads. 100,000 orchestrations per
            billing cycle are included. Each additional 10,000 orchestrations
            are 3.00 USD and billed manually, via email invoicing, the following
            month. An orchestration represents a load on DejaVuly APIs,
            Databases, and Network I/O. Real-time orchestration count is
            available in the application dashboard.
          </li>
          <li>
            <b>
              You may cancel your subscription at any time, however there are no
              refunds. A FREE compatibility test is available{" "}
              <a
                href="https://site.dejavuly.com/compatibility"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </b>
          </li>
        </ol>
        <p className="mb-0">
          Back to <a href="#createAcct">top</a>.
        </p>
      </div>
    </form>
  );
}

export default SignUpForm;
