import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clientLogin, verifyLogin } from "../../api";
import Spinner from "../../components/Spinner";
import { alertError } from "../../library/alert";

function Login() {
  const [verifying, setVerifying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      try {
        setVerifying(true);
        const responseJson: any = await verifyLogin();
        if (responseJson && !responseJson.error) {
          navigate("/dashboard");
        }
        setVerifying(false);
      } catch (e) {
        setVerifying(false);
      }
    }
    loadData();
  }, [navigate]);

  const onClickLogin = async () => {
    if (!email) alertError("Enter valid email.");
    if (!password) alertError("Enter password.");
    if (email && password) {
      setLoading(true);
      const responseJson: any = await clientLogin({ email, password });
      setLoading(false);
      if (responseJson && !responseJson.error) {
        localStorage.setItem("token", responseJson.token);
        navigate("/dashboard");
      }
      if (responseJson && responseJson.error) {
        alertError(responseJson.error);
      }
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img
                      src="/assets/img/dejavuly-logo-transparent.png"
                      alt="DejaVuly Dashboard"
                      style={{ maxHeight: 50 }}
                    />
                  </Link>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your email & password to login
                      </p>
                    </div>

                    {verifying ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : (
                      <form className="row g-3 needs-validation">
                        <div className="col-12">
                          <label htmlFor="yourEmail" className="form-label">
                            Email
                          </label>
                          <div className="input-group has-validation">
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              id="yourEmail"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="col-12">
                          <p className="small mb-0 mt-0 float-end">
                            <Link to="/resetpassword">Forgot Password?</Link>
                          </p>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="button"
                            onClick={onClickLogin}
                            disabled={loading}
                          >
                            Login
                          </button>
                        </div>
                        <div className="col-12">
                          <p className="mb-0">
                            Don't have account?{" "}
                            <Link to="/signup">Subscribe now.</Link>
                          </p>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Login;
