function ErrorMessage({ message }: { message?: string }) {
  if (message) {
    return (
      <div className="row mt-3">
        <p className="text-danger">{message}</p>
      </div>
    );
  }
  return null;
}

export default ErrorMessage;
