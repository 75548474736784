import { useState } from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "../../api";
import { alertError, alertInfo } from "../../library/alert";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onClickResetPassword = async () => {
    if (!email) alertError("Enter valid email.");
    if (email) {
      setLoading(true);
      const responseJson: any = await resetPassword({ email });
      setLoading(false);
      if (responseJson && !responseJson.error) {
        setEmail("");
        alertInfo("Password reset email sent. Please check your email.");
      }
      if (responseJson && responseJson.error) {
        alertError(responseJson.error);
      }
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img
                      src="/assets/img/dejavuly-logo-transparent.png"
                      alt="DejaVuly Dashboard"
                      style={{ maxHeight: 50 }}
                    />
                  </Link>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Reset your password
                      </h5>
                    </div>

                    <form className="row g-3 needs-validation">
                      <div className="col-12">
                        <label htmlFor="yourEmail" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            id="yourEmail"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          type="button"
                          onClick={onClickResetPassword}
                          disabled={loading}
                        >
                          Reset Password
                        </button>
                      </div>
                      <div className="col-12">
                        <p className="mb-0">
                          Don't have account?{" "}
                          <Link to="/signup">Subscribe now.</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default ResetPassword;
