import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getTinymiceToken } from "../../api";

type ContentEditorProps = {
  initialValue?: string;
  onChange: (value: string) => void;
};

export default function ContentEditor({
  initialValue = "",
  onChange,
}: ContentEditorProps) {
  const editorRef: any = useRef(null);

  const getToken = async () => {
    const token: string = (await getTinymiceToken()) as string;
    return token;
  };

  return (
    <>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        apiKey={process.env.REACT_APP_TINYMICE_API_KEY}
        init={{
          branding: false,
          valid_elements : '+*[*]',
          font_formats:
            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "code",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "media",
            "pageembed",
            "table",
            "wordcount",
            "tinydrive",
          ],
          toolbar1:
            "undo redo | blocks | fontfamily fontsize | bold italic forecolor backcolor |" +
            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
          toolbar2:
            "removeformat table | link anchor | insertfile image media | pageembed code | preview fullscreen",
          tinydrive_token_provider: async function (
            success: any,
            failure: any
          ) {
            const token = await getToken();
            success({ token });
          },
        }}
        onEditorChange={(value: string) => onChange(value)}
      />
    </>
  );
}
