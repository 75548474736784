import UnauthHeader from "../../components/UnauthHeader";
import InnerHTML from "dangerously-set-html-content";

const testboot = `
<div class="container col-xxl-8 px-4 py-5" style="padding-bottom:1rem !important">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <img src="assets/img/dejavuly-logo-transparent.png" class="d-block mx-lg-auto img-fluid" id="homepage_logo" loading="lazy">
      </div>
      <div class="col-lg-6">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3" style="color:#012970;">Welcome!</h1>
        <h3 class="fw-bold text-body-emphasis" style="color:#012970;">DejaVuly makes websites <em>smarter</em> and more <em>dynamic</em>.</h3>
        <br>
        <a href="https://site.dejavuly.com/documentation"><button class="btn btn-primary" type="button" style="width:200px">Documentation</button></a>
        <br><br>
        <a href="login"><button class="btn btn-primary" type="button" style="width:200px">Log In</button></a>
        <br>
      </div>
    </div>
</div>  

<div class="text-center"><br><br></div>


`;

function Landing() {
  return (
    <>
      <UnauthHeader active="home" />
      <div className="container">
        <InnerHTML html={testboot} />
      </div>
    </>
  );
}

export default Landing;
