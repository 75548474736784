import { AppContext } from "../../library/context";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { resetPassword, updateClient } from "../../api";
import { alertError, alertInfo, alertSuccess } from "../../library/alert";
import ErrorMessage from "../../components/ErrorMessage";

function PersonalSettingsForm() {
  const appData = useContext(AppContext);
  const [error, setError] = useState("");
  const [disableResetPassword, setDisableResetPassword] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState<any>(appData.user);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required("Please enter first name")
      .max(100, "Max 100 chars alowed in first name."),
    lastName: Yup.string()
      .trim()
      .required("Please enter last name")
      .max(100, "Max 100 chars alowed in last name."),
    email: Yup.string()
      .trim()
      .email("Please enter valid email")
      .required()
      .max(500, "Max 500 chars alowed in email."),
  });

  const validate = async () => {
    try {
      await validationSchema.validate({
        lastName: user.last_name,
        firstName: user.first_name,
        email: user.email,
      });
      return "";
    } catch (err: any) {
      return err.errors.join(", ");
    }
  };

  const onClickUpdateAccountDetails = async (e: any) => {
    setError("");
    e.preventDefault();
    const error = await validate();
    if (error) {
      setError(error);
      return;
    }
    setSaving(true);
    const payload = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
    };
    const response: any = await updateClient(payload);
    setSaving(false);
    if (response && !response.error) {
      appData.setUser({ ...user, ...payload });
      localStorage.setItem("token", response);
      alertSuccess("Account details updated");
    } else {
      setError(response.error);
    }
  };

  const onClickResetPassword = async () => {
    if (!user.email) alertError("Email not available.");
    if (user.email) {
      const responseJson: any = await resetPassword({ email: user.email });
      setDisableResetPassword(true);
      if (responseJson && !responseJson.error) {
        alertInfo("Password reset email sent. Please check your email.");
      }
      if (responseJson && responseJson.error) {
        alertError(responseJson.error);
      }
    }
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mt-1">
                <label htmlFor="inputText" className="col-form-label">
                  <b>Personal Details</b>
                </label>
              </div>
              <div className="row">
                <form onSubmit={onClickUpdateAccountDetails}>
                  <div className="row mb-3 mt-3">
                    <label
                      htmlFor="inputText"
                      className="col-sm-2 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        id="firstName"
                        placeholder="Enter First Name"
                        className="form-control"
                        value={user.first_name}
                        required
                        onChange={(e) =>
                          setUser({ ...user, first_name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <label
                      htmlFor="inputText"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        id="lastName"
                        placeholder="Enter Last Name"
                        className="form-control"
                        value={user.last_name}
                        required
                        onChange={(e) =>
                          setUser({ ...user, last_name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <label
                      htmlFor="inputText"
                      className="col-sm-2 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="email"
                        id="lastName"
                        placeholder="Enter Email"
                        className="form-control"
                        value={user.email}
                        disabled
                      />
                    </div>
                  </div>
                  <ErrorMessage message={error} />
                  <div className="row mb-3">
                    <div className="col-6 col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={saving}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row mt-1">
                <label htmlFor="inputText" className="col-form-label">
                  <b>Update Password</b>
                </label>
              </div>
              <div className="row">
                <form>
                  <div className="row mb-3">
                    <div className="col-8 col-md-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={onClickResetPassword}
                        disabled={disableResetPassword}
                      >
                        Send Reset Password Link
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalSettingsForm;
