const DEFAULT_MESSAGE = "Something went wrong";

/**
 * Get error message string from HTTP response
 * @param response
 * @returns string
 */
async function getErrorMessage(response: any) {
  try {
    let responseJson = await response.json();
    return responseJson.error || DEFAULT_MESSAGE;
  } catch (error) {
    return DEFAULT_MESSAGE;
  }
}

/**
 * Helper to convert Javascript date to date format used in datetime HTML input
 * @param str
 * @returns string
 */
function getFormattedDateForInput(str: string) {
  const arr = str.split(":");
  arr.pop();
  const result = arr.join(".");
  return result.replace(".", ":");
}

/**
 * Get date offset for user
 * @returns number
 */
function getDateOffset() {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  return offset;
}

/**
 * Helper to sum count key from array of objects
 * @param data
 * @returns number
 */
function reduceCount(data: any) {
  if (!data || data.error) {
    return 0;
  }
  return data.reduce((n: number, { count }: { count: number }) => n + count, 0);
}

/**
 * Generate series object as required by line chart data for two series
 * @param listOne
 * @param listTwo
 * @param nameOne
 * @param nameTwo
 * @returns object
 */
function getCombinedSeries(
  listOne: any[],
  listTwo: any[],
  nameOne: string,
  nameTwo: string
) {
  const categories = getCategories(listOne, listTwo);

  const dataOne: any = [];
  const dataTwo: any = [];
  for (let i = 0; i < categories.length; i++) {
    const indexOne = listOne.findIndex((x) => x.day === categories[i]);
    dataOne.push(indexOne === -1 ? 0 : listOne[indexOne].count);

    const indexTwo = listTwo.findIndex((x) => x.day === categories[i]);
    dataTwo.push(indexTwo === -1 ? 0 : listTwo[indexTwo].count);
  }
  return [
    {
      name: nameOne,
      data: dataOne,
    },
    {
      name: nameTwo,
      data: dataTwo,
    },
  ];
}

/**
 * Get sorted unique day map from two array of objects
 * @param listOne
 * @param listTwo
 * @returns array
 */
function getCategories(listOne: any[], listTwo: any[]) {
  if (!listOne || !listTwo) {
    return [];
  }
  const listOnekeys = listOne.map((a) => a.day);
  const listTwokeys = listTwo.map((a) => a.day);
  const keys = [...listOnekeys, ...listTwokeys];
  const categoriesSet = new Set();
  for (let i = 0; i < keys.length; i++) {
    categoriesSet.add(keys[i]);
  }
  return Array.from(categoriesSet).sort();
}

/**
 * Get date relative to current date
 * @param relative
 * @returns Date
 */
function getRelativeDate(relative: number) {
  return new Date(Date.now() + relative * 86400000 - getDateOffset());
}

export {
  getFormattedDateForInput,
  getErrorMessage,
  getDateOffset,
  reduceCount,
  getCombinedSeries,
  getCategories,
  getRelativeDate,
};
