import Chart from "react-apexcharts";

type LineCharProps = {
  categories: any[];
  series: any[];
};
export default function LineChart({ categories, series }: LineCharProps) {
  const chartData = {
    series,
    options: {
      chart: {
        type: "area" as any,
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth" as any,
        width: 4,
      },
      xaxis: {
        type: "datetime" as any,
        categories,
      },
      yaxis: {
        forceNiceScale: true,
        min: 0,
      },
      tooltip: {
        x: {
          format: "MM/dd/yy",
        },
      },
      legend: {
        height: 50,
        fontSize: "16px",
        itemMargin: {
          horizontal: 15,
          vertical: 0,
        },
      },
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height="400"
    />
  );
}
