import LineChart from "../../components/LineChart";

import { getCategories, getCombinedSeries } from "../../library/utility";

type GraphsProp = {
  fromDate: string;
  toDate: string;
  visitorsChartData: any[];
  contentClickedChartData: any[];
  contentViewedChartData: any[];
  watchersViewedChartData: any[];
  watchersClickedChartData: any[];
  uniqueVisitorsChartData: any[];
};

function Graphs({
  fromDate,
  toDate,
  visitorsChartData,
  contentClickedChartData,
  contentViewedChartData,
  watchersViewedChartData,
  watchersClickedChartData,
  uniqueVisitorsChartData,
}: GraphsProp) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-4 ms-4 me-4">
            <b className="primary-title">Daily Visitors Stats</b>
            <small className="ms-2 date-range-text">
              {fromDate.split("T")[0]} to {toDate.split("T")[0]}
            </small>
            <LineChart
              categories={getCategories(
                visitorsChartData,
                uniqueVisitorsChartData
              )}
              series={getCombinedSeries(
                visitorsChartData,
                uniqueVisitorsChartData,
                "Visitors",
                "Unique Visitors"
              )}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mt-4 ms-4 me-4">
            <b className="primary-title">Daily Watchers Stats</b>
            <small className="ms-2 date-range-text">
              {fromDate.split("T")[0]} to {toDate.split("T")[0]}
            </small>
            <LineChart
              categories={getCategories(
                watchersClickedChartData,
                watchersViewedChartData
              )}
              series={getCombinedSeries(
                watchersClickedChartData,
                watchersViewedChartData,
                "Watchers Clicked",
                "Watchers Viewed"
              )}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mt-4 ms-4 me-4">
            <b className="primary-title">Daily Content Stats</b>
            <small className="ms-2 date-range-text">
              {fromDate.split("T")[0]} to {toDate.split("T")[0]}
            </small>
            <LineChart
              categories={getCategories(
                contentClickedChartData,
                contentViewedChartData
              )}
              series={getCombinedSeries(
                contentClickedChartData,
                contentViewedChartData,
                "Content Clicked",
                "Content Viewed"
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Graphs;
