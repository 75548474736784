import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectorById, saveSelector, updateSelector } from "../../../api";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/Spinner";
import { alertSuccess } from "../../../library/alert";
import { AppContext } from "../../../library/context";
import { Selectors } from "../../../library/types";

function Create() {
  const navigate = useNavigate();
  const appData = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selector, setSelector] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState<number | null>(null);

  useEffect(() => {
    async function loadEditData() {
      const id = searchParams.get("id");
      if (id) {
        setLoading(true);
        const response: any = await getSelectorById(
          appData.cid as number,
          parseInt(id)
        );
        if (response && !response.error) {
          const selector: Selectors = response;
          setEditId(parseInt(selector.id, 10));
          setName(selector.name);
          setDescription(selector.description);
          setSelector(selector.selector);
        }
        setLoading(false);
      }
    }
    loadEditData();
  }, [searchParams, appData]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    let error = "";
    if (!name) {
      error += "Name is required. ";
    }
    if (!description) {
      error += "Description is required. ";
    }
    if (!selector) {
      error += "Selector is required. ";
    }
    if (error) {
      setError(error);
      return;
    }

    const payload = {
      name,
      description,
      selector,
      cid: appData.cid,
      id: editId,
    };

    const responseJson: any = editId
      ? await updateSelector(payload)
      : await saveSelector(payload);
    if (!responseJson.error) {
      alertSuccess("Saved");
      setError("");
      setDescription("");
      setName("");
      setSelector("");
      setEditId(null);
      navigate("/selectors/list");
    } else {
      setError(responseJson.error);
    }
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Selectors</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">Selector</li>
            <li className="breadcrumb-item active">
              {editId ? "Update" : "Define"}
            </li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {!loading && (
                <div className="card-body pt-3">
                  <b className="primary-title">
                    {editId ? "Update" : "Define"} Selector
                  </b>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <p className="col-12 col-md-9">
                        <br />
                        When DejaVuly has work to do, it will try and find the selector you specify below.
                      </p>
                      <p>
                        Selectors are used to "find" (or select) the HTML elements you want to
                        add your dynamic content into. Or, in the case of a Watcher, it's the
                        HTML element you want to track for a Click and/or a View.
                        <br />
                        <br />
                        Getting your selector defined correctly is a very
                        important part of being successful with DejaVuly. Be sure to review the <a href="https://site.dejavuly.com/documentation" rel="noopener noreferrer" target="_blank">Documentation</a>.{" "}
                      </p>
                      <p>Example selectors are:</p>
                      <ul>
                        <li>#home</li>
                        <li>#header &gt; div &gt; a &gt; img</li>
                        <li>img.logo</li>
                        <li>p.products</li>
                        <li>div.news</li>
                      </ul>
                      <p>
                        Give your selector a meaningful name, along with a short
                        description, so you can refer to it later. For best
                        results use an id if possible, but any valid selector
                        will work.{" "}
                      </p>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row mb-3 mt-3">
                      {" "}
                      <label
                        htmlFor="sequence_name"
                        className="col-sm-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          id="sequence_name"
                          placeholder="Selector Name"
                          className="form-control"
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="selector"
                        className="col-sm-2 col-form-label"
                      >
                        Selector
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          id="selector"
                          placeholder="Selector Key"
                          className="form-control"
                          value={selector}
                          required
                          onChange={(e) => setSelector(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="sequence_description"
                        className="col-sm-2 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-sm-5">
                        <textarea
                          id="sequence_description"
                          placeholder="Selector Description"
                          className="form-control"
                          value={description}
                          required
                          onChange={(e) => setDescription(e.target.value)}
                          rows={5}
                        />
                      </div>
                    </div>
                    <ErrorMessage message={error} />
                    <div className="row mb-3">
                      <div className="col-sm-1">
                        <button type="submit" className="btn btn-primary">
                          {editId ? "Update" : "Save"}
                        </button>
                      </div>
                      <div className="col-sm-1">
                        {editId && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              navigate("/selectors/list");
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {loading && (
                <div className="card-body pt-3">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Create;
