import { Link } from "react-router-dom";
import SignUpForm from "./SignUpForm";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

function SignUp() {
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img
                      src="/assets/img/dejavuly-logo-transparent.png"
                      alt="DejaVuly Dashboard"
                      style={{ maxHeight: 50 }}
                    />
                  </Link>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Subscribe to create an account
                      </h5>
                      <p className="text-center small">
                        Enter your personal details
                      </p>
                    </div>

                    <div id="card-element-errors" role="alert"></div>
                    <Elements stripe={stripePromise}>
                      <SignUpForm />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default SignUp;
