import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { getStats, ping } from "../../api";
import DateRange from "../../components/DateRange";
import { AppContext } from "../../library/context";

import {
  getFormattedDateForInput,
  getRelativeDate,
  reduceCount,
} from "../../library/utility";
import Graphs from "./Graphs";
import Cards from "./Cards";
import { CountStat } from "../../library/types";
import Tables from "./Tables";

const DEFAULT_STAT_START: CountStat = {
  loading: true,
  value: null,
};

const DEFAULT_STAT_ERROR: CountStat = {
  loading: false,
  value: "N/A",
};

const VIEWS = ["graphs", "tables"];

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(VIEWS[0]);
  const [fromDate, setFromDate] = useState(
    getFormattedDateForInput(getRelativeDate(-6).toISOString())
  );
  const [toDate, setToDate] = useState(
    getFormattedDateForInput(getRelativeDate(0).toISOString())
  );
  const appData = useContext(AppContext);
  const [watchersClicked, setWatchersClicked] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [watchersViewed, setWatchersViewed] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [contentClicked, setContentClicked] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [contentViewed, setContentViewed] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [visitors, setVisitors] = useState<CountStat>(DEFAULT_STAT_START);
  const [orchestrations, setOrchestrations] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [uniqueVisitors, setUniqueVisitors] =
    useState<CountStat>(DEFAULT_STAT_START);
  const [uniqueVisitorsChartData, setUniqueVisitorsChartData] = useState<any[]>(
    []
  );
  const [visitorsChartData, setVisitorsChartData] = useState<any[]>([]);
  const [visitorsUrlChartData, setVisitorsUrlChartData] = useState<any[]>([]);
  const [watchersClickedChartData, setWatchersClickedChartData] = useState<
    any[]
  >([]);
  const [watchersViewedChartData, setWatchersViewedChartData] = useState<any[]>(
    []
  );
  const [contentClickedChartData, setContentClickedChartData] = useState<any[]>(
    []
  );
  const [contentViewedChartData, setContentViewedChartData] = useState<any[]>(
    []
  );
  const [watchersClickedByIdChartData, setWatchersClickedByIdChartData] =
    useState<any[]>([]);
  const [watchersViewedByIdChartData, setWatchersViewedByIdChartData] =
    useState<any[]>([]);
  const [contentClickedByIdChartData, setContentClickedByIdChartData] =
    useState<any[]>([]);
  const [contentViewedByIdChartData, setContentViewedByIdChartData] = useState<
    any[]
  >([]);

  useEffect(() => {
    loadData();
  }, [appData]);

  async function loadData(inputFrom?: string, inputTo?: string) {
    try {
      const from = inputFrom ?? fromDate;
      const to = inputTo ?? toDate;
      setLoading(true);

      // Worksround for firebase cold start problem
      await ping();

      const QUERIES = [
        "VISITORS",
        "UNIQUE_VISITORS",
        "WATCHER_CLICK",
        "WATCHER_VIEW",
        "CONTENT_CLICK",
        "CONTENT_VIEW",
        "WATCHER_VIEW_GROUP_BY_ID",
        "WATCHER_CLICK_GROUP_BY_ID",
        "CONTENT_VIEW_GROUP_BY_ID",
        "CONTENT_CLICK_GROUP_BY_ID",
        "ORCHESTRATIONS",
        "VISITOR_URLS",
      ];
      const apiCalls: Array<any> = [];
      QUERIES.forEach((QUERY) => {
        apiCalls.push(getStats(from, to, QUERY));
      });
      const data: any = await Promise.all(apiCalls);

      if (data && data[0]) {
        setVisitors({
          loading: false,
          value: reduceCount(data[0]),
        });
        setVisitorsChartData(data[0]);
      } else {
        setVisitors(DEFAULT_STAT_ERROR);
      }
      if (data && data[10]) {
        setOrchestrations({
          loading: false,
          value: reduceCount(data[10]),
        });
      } else {
        setOrchestrations(DEFAULT_STAT_ERROR);
      }
      if (data && data[11]) {
        setVisitorsUrlChartData(data[11]);
      } else {
        setVisitorsUrlChartData([]);
      }
      if (data && data[1]) {
        setUniqueVisitors({
          loading: false,
          value: reduceCount(data[1]),
        });
        setUniqueVisitorsChartData(data[1]);
      } else {
        setUniqueVisitors(DEFAULT_STAT_ERROR);
      }
      if (data && data[2]) {
        setWatchersClicked({
          loading: false,
          value: reduceCount(data[2]),
        });
        setWatchersClickedChartData(data[2]);
      } else {
        setWatchersClicked(DEFAULT_STAT_ERROR);
      }
      if (data && data[3]) {
        setWatchersViewed({
          loading: false,
          value: reduceCount(data[3]),
        });
        setWatchersViewedChartData(data[3]);
      } else {
        setWatchersViewed(DEFAULT_STAT_ERROR);
      }
      if (data && data[4]) {
        setContentClicked({
          loading: false,
          value: reduceCount(data[4]),
        });
        setContentClickedChartData(data[4]);
      } else {
        setContentClicked(DEFAULT_STAT_ERROR);
      }
      if (data && data[5]) {
        setContentViewed({
          loading: false,
          value: reduceCount(data[5]),
        });
        setContentViewedChartData(data[5]);
      } else {
        setContentViewed(DEFAULT_STAT_ERROR);
      }
      if (data && data[6]) {
        setWatchersViewedByIdChartData(data[6]);
      }
      if (data && data[7]) {
        setWatchersClickedByIdChartData(data[7]);
      }
      if (data && data[8]) {
        setContentViewedByIdChartData(data[8]);
      }
      if (data && data[9]) {
        setContentClickedByIdChartData(data[9]);
      }
      setLoading(false);
    } catch (e: any) {
      console.error(e);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="pagetitle">
        <h1>Dynamic Content Overview</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row card">
          <div className="col-lg-12">
            <DateRange
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              onClickSubmit={(from?: string, to?: string) => loadData(from, to)}
              disabled={loading}
            />
            <div className="row mt-5 ms-4 me-4 mb-4">
              <ul className="nav nav-tabs" id="statsTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link hover-pointer ${
                      view === VIEWS[0] ? "active" : ""
                    }`}
                    id="graphs-tab"
                    onClick={() => setView(VIEWS[0])}
                  >
                    <b>Graphs</b>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link hover-pointer ${
                      view === VIEWS[1] ? "active" : ""
                    }`}
                    id="tables-tab"
                    onClick={() => setView(VIEWS[1])}
                  >
                    <b>Tables</b>
                  </a>
                </li>
              </ul>
            </div>
            {view === VIEWS[0] && (
              <Cards
                visitors={visitors}
                uniqueVisitors={uniqueVisitors}
                watchersViewed={watchersViewed}
                watchersClicked={watchersClicked}
                contentViewed={contentViewed}
                contentClicked={contentClicked}
                orchestrations={orchestrations}
              />
            )}

            {view === VIEWS[0] && (
              <Graphs
                fromDate={fromDate}
                toDate={toDate}
                contentClickedChartData={contentClickedChartData}
                contentViewedChartData={contentViewedChartData}
                watchersViewedChartData={watchersViewedChartData}
                watchersClickedChartData={watchersClickedChartData}
                uniqueVisitorsChartData={uniqueVisitorsChartData}
                visitorsChartData={visitorsChartData}
              />
            )}

            {view === VIEWS[1] && (
              <Tables
                watchersClickedByIdChartData={watchersClickedByIdChartData}
                watchersViewedByIdChartData={watchersViewedByIdChartData}
                contentClickedByIdChartData={contentClickedByIdChartData}
                contentViewedByIdChartData={contentViewedByIdChartData}
                visitorsUrlChartData={visitorsUrlChartData}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
